import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

import { Config } from "app/config/config.service";
import { IpRange } from "app/data/entities/iprange";

@Injectable()
export class IpListService {

    private url = `${this.config.casinoManagementServiceUrl}/api/agent`;

    constructor(private httpClient: HttpClient, private config: Config) {
    }

    public getRanges(agentId: string): Observable<any> {
        return this.httpClient.get<IpRange[]>(`${this.url}/${agentId}/ipwhitelist`);
    }

    public alterRanges(ranges: IpRange[], agentId: string): Observable<any> {
        return this.httpClient.put(`${this.url}/${agentId}/ipwhitelist`, ranges);
    }
}
