import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";

import { BroadcasterService } from "app/shared/utils/broadcaster.service";
import { ConstantsService } from "app/shared/utils/constants.service";
import { NavigationOptionsService } from "app/services/navigation-options.service";

@Component({
    selector: "[app-component-navigation]",
    templateUrl: "./agents-navigation.component.html",
    styleUrls: ["agents-navigation.component.css"]
})
export class AgentNavigationComponent {
    @Input() agents: Object;
    @Input() currentAgentId: string;
    @Input() currentPath: string;
    @Input() loadingAgentId: string;

    constructor(private broadcasterService: BroadcasterService,
        private constantsService: ConstantsService,
        private route: Router,
        private navigationOptionsService: NavigationOptionsService) {
    }

    emitAgentActionBroadcast(agentId: string, path: string) {
        this.broadcasterService.broadcast(this.constantsService.AGENT_CHANGE_EVENT, agentId);
        if (path) {
            // verify that user has rights for given path
            const options = this.navigationOptionsService.getOptions();

            for (const o of options) {
                if (o && path === o.path && o.visible(agentId)) {
                        this.route.navigate([`/${agentId}/${path}`]);
                        return;
                }
            }
        }
        const landingRoute = this.navigationOptionsService.getLandingRoute(agentId);
        this.route.navigate([`/${agentId}/${landingRoute}`]);
    }
}
