import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient, HttpParams } from "@angular/common/http";


import { Observable } from "rxjs";

import { Config } from "../config/config.service";

import { TokenManager } from "../shared/utils/token-manager";
import { IRole } from "../data/entities/role";
import { Claim } from "app/data/entities/claim";


@Injectable()
export class RoleService {


    private url = `${this.config.apiUrl}/api/Role`;

    constructor(private http: HttpClient,
        private config: Config,
        private tokenManager: TokenManager) {
    }
    public getRoles(): Observable<IRole[]> {
        return this.http.get<IRole[]>(this.url);
    }

    public updateRoles(roles: IRole[]): Observable<any> {
        return this.http.put(this.url, roles, { responseType: "text" });
    }


}
