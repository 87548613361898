import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";

import { JsonApiService } from "./api/json-api.service";
import { LayoutService } from "../shared/layout/layout.service";
import { UserSmartAdminService } from "app/shared/user";
import { UserService } from "app/users/user.service";
import { CasinoService } from "app/casinos/casino.service";
import { CasinoAgentService } from "app/casinos/casino.service";
import { MulticashBalanceService } from "app/agents/multicash-balance/multicashBalance.service";


import { throwIfAlreadyLoaded } from "./guards/module-import-guard";
import { AlertModule, BsDropdownModule, ProgressbarModule, TabsModule, TooltipModule } from "ngx-bootstrap";


@NgModule({
    imports: [
        CommonModule,
        TooltipModule.forRoot(),
        BsDropdownModule.forRoot(),
        ProgressbarModule.forRoot(),
        AlertModule.forRoot(),
        TabsModule.forRoot(),
    ],
    declarations: [],
    providers: [
        JsonApiService,
        LayoutService,
        UserService,
        UserSmartAdminService,
        CasinoService,
        CasinoAgentService,
        MulticashBalanceService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, "CoreModule");
    }
}
