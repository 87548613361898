import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from 'rxjs';

export class PaginatorIntlService extends MatPaginatorIntl {
  translate: TranslateService;

  itemsPerPageLabel = 'Items per page';
  itemsPerPage = 'paginator.items_per_page';
  nextPageLabel = 'Next page';
  nextPage = 'paginator.next_page';
  previousPageLabel = 'Previous page';
  previousPage = 'paginator.previous_page';
  ofLabel = 'of';
  of = 'paginator.of';

  getRangeLabel = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.ofLabel} 0`;
    }

    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.

    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.ofLabel} ${length}`;
  };

  injectTranslateService(translate: TranslateService) {
    this.translate = translate;
    
    this.translateLabels();
    this.translate.onLangChange.subscribe(() => {
      this.translateLabels();
    });
  }

  translateLabels() {
    forkJoin([
      this.translate.get(this.itemsPerPage),
      this.translate.get(this.nextPage),
      this.translate.get(this.previousPage),
      this.translate.get(this.of),
    ]).subscribe((translations: string[]) => {
      this.itemsPerPageLabel = translations[0];
      this.nextPageLabel = translations[1];
      this.previousPageLabel = translations[2];
      this.ofLabel = translations[3];
    });

    this.changes.next();
  }

}