
import {map} from "rxjs/operators";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "app/shared/utils/local-storage.service";

export class CommonService<T> {

    constructor(
        protected httpClient: HttpClient,
        private localStorageService: LocalStorageService,
        protected url: string,
        protected key?: string) {
    }

    public getList(): Observable<T[]> {
        const values = this.key ? this.localStorageService.getItem(this.getLocalStorageKey()) : null;
        if (values) {
            return new Observable(observer => {
                observer.next(JSON.parse(values));
                observer.complete();
            });
        } else {
            return this.httpClient.get<T[]>(this.getUrl()).pipe(map((data: T[]) => {
                if (this.key) {
                    this.localStorageService.setItem(this.getLocalStorageKey(), JSON.stringify(data));
                }
                return data;
            }));
        }
    }

    protected getUrl(): string {
        return this.url;
    }
    protected getLocalStorageKey(): string {
        return this.key;
    }
}
