
import {throwError as observableThrowError,  Observable } from "rxjs";

import {catchError, timeout} from "rxjs/operators";
import { Injectable } from "@angular/core";
import { TokenManager } from "app/shared/utils/token-manager";
import { Http, XHRBackend, RequestOptions, Request, RequestOptionsArgs, Response, Headers } from "@angular/http";

@Injectable()
export class HttpService extends Http {
    private token: string;
    constructor(backend: XHRBackend,
                options: RequestOptions,
                private tokenManager: TokenManager) {
        super(backend, options);
    }

    request(url: string | Request, options?: RequestOptionsArgs): Observable<Response> {
        this.token = this.tokenManager.retrieveToken();
        if (typeof url === "string") { // meaning we have to add the token to the options, not in url
            if (!options) {
                // let's make option object
                options = { headers: new Headers() };
            }
            options.headers.set("Authorization", this.token);
        } else {
            // we have to add the token to the url object
            url.headers.set("Authorization", this.token);
        }
        return super.request(url, options).pipe(
                    timeout(35000),
                    catchError(this.catchError(this)),);
    }

    private catchError(self: HttpService) {
        // we have to pass HttpService's own instance here as `self`
        return (res: Response) => {
            console.log(res);
            /*if (res.status === 401 || res.status === 403) {
                // if not authenticated
                console.log(res);
            }*/
            return observableThrowError(res);
        };
    }
}