import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";

import {AuthService} from "./auth/auth.service";
import { TokenManager } from "app/shared/utils/token-manager";


@Injectable()
export class LoggedGuard implements CanActivate {

    constructor(private router: Router,
                private authService: AuthService,
                private tokenManager: TokenManager) {

    }

    canActivate() {
        const token = this.tokenManager.retrieveToken();
        if (token) {
            this.authService.isLoggedIn = true;
            this.router.navigate([""]);
            return false;
        }
        return true;
    }
}
