import {Injectable} from "@angular/core";

import {Observable} from "rxjs";

import {Config} from "app/config/config.service";
import {HttpHeaders } from "@angular/common/http";
import {Pos} from "app/data/entities/pos";
import {Terminal} from "app/data/entities/terminal";
import {HttpClient, HttpParams} from "@angular/common/http";
import {AdjustBalanceCommand} from "../data/entities/commands/adjust-balance-command";
import {PosCommand} from "../data/entities/pos-command";
import {ReportParameters, RequestReportCommand} from "../data/entities/commands/request-report-command";
import {Reports} from "../data/entities/commands/reports";
import {PosUser } from "app/data/entities/posUser";
import {PosUserChangePassword } from "app/data/entities/pos-user-change-password";


@Injectable()
export class PosService {
    private posesUrl = `${this.config.apiUrl}/api/poses`;
    private posUrl = `${this.config.apiUrl}/api/pos`;
    private posUserUrl = `${this.config.apiUrl}/api/posUser`;
    private terminalsUrl = `${this.config.apiUrl}/api/terminals/agentterminals`;

    constructor(private httpClient: HttpClient,
                private config: Config) {
    }

    public getPoses(agentId: string): Observable<Pos[]> {
        return this.httpClient.get<Pos[]>(this.posesUrl, {
            params: new HttpParams().set("agentId", agentId)
        });
    }

    public getPos(posId: string): Observable<Pos> {
        return this.httpClient.get<Pos>(this.posUrl, {
            params: new HttpParams().set("posId", posId)
        });
    }

    public createPos(pos: Pos): Observable<string> {
        return this.httpClient.put(this.posUrl, pos, {responseType: "text" });
    }

    public updatePos(pos: Pos): Observable<string> {
        return this.httpClient.post(this.posUrl, pos, {responseType: "text" });
    }

    public wipePos(pos: Pos): Observable<string> {
        return this.httpClient.post(`${this.posUrl}/wipe`, pos, {responseType: "text" });
    }

    public deletePos(posId: string) {
        return this.httpClient.delete(this.posUrl, { params: new HttpParams().set("posId", posId ), responseType: "text" });
    }

    public adjustBalance(posId: string, amount: number, userId: string): Observable<string> {
        const command = new AdjustBalanceCommand();
        command.amount = amount;
        command.userId = userId;

        return this.httpClient.put(`${this.config.apiUrl}/api/pos/commands/adjust_balance`, command, {
            params: new HttpParams().set("posId", posId), responseType: "text"
        });
    }

    public requestBalanceReport(posId: string): Observable<string> {
        const command = new RequestReportCommand();
        command.report = new ReportParameters();
        command.report.type = Reports.BalanceReport;

        return this.httpClient.put(`${this.config.apiUrl}/api/pos/commands/request_report`, command, {
            params: new HttpParams().set("posId", posId), responseType: "text"
        });
    }

    public getTerminals(agentId: string): Observable<Terminal[]> {
        return this.httpClient.get<Terminal[]>(this.terminalsUrl,
            {
                params: new HttpParams().set("agentId", agentId).set("includingSubAgents", "true")
            });

    }

    public getPendingCommands(posId: string): Observable<PosCommand[]> {
        return this.httpClient.get<PosCommand[]>(`${this.config.apiUrl}/api/pos/commands`,
            {
                params: new HttpParams().set("posId", posId)
            });

    }

    public createPosUser(agentId: string, posUser: PosUser): Observable<string> {
        return this.httpClient.post(`${this.posUserUrl}/create?agentId=${agentId}`, posUser, {responseType: "text" });
    }

    public updatePosUser(agentId: string, posUser: PosUser): Observable<string> {
        return this.httpClient.put(`${this.posUserUrl}/update?agentId=${agentId}`, posUser, {responseType: "text" });
    }

    public getPosUser(agentId: string, posUserId: string): Observable<PosUser> {
        return this.httpClient.get<PosUser>(`${this.posUserUrl}?agentId=${agentId}&posUserId=${posUserId}`, {responseType: "json" });
    }

    public changePosUser(agentId: string, passwordData: PosUserChangePassword): Observable<string> {
        return this.httpClient.put(`${this.posUserUrl}/change-password?agentId=${agentId}`, passwordData, {responseType: "text" });
    }

}
