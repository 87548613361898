import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameHistoryPublicComponent } from './game-history-public.component';
import { SlotsDetailsPublicComponent } from "app/generic-list/custom-actions/player-game-actions/slots-details.public.component";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule} from "app/material.module";
import { SharedReportsModule } from "app/reports/shared-reports.module";

@NgModule({

  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    SharedReportsModule
  ],
  declarations: [
    GameHistoryPublicComponent,
    SlotsDetailsPublicComponent
  ]
})
export class GameHistoryPublicModule { }
