import { Injectable } from "@angular/core";
import { Config } from "app/config/config.service";


import { ConstantsService } from "app/shared/utils/constants.service";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "app/shared/utils/local-storage.service";
import { CommonService } from "./common.service";
import { IPosRole } from "app/data/entities/pos-roles";

@Injectable()
export class PosRolesService extends CommonService<IPosRole> {
    constructor(httpClient: HttpClient,
        localStorageService: LocalStorageService,
        constantService: ConstantsService,
        config: Config) {
        super(
            httpClient,
            localStorageService,
            `${config.apiUrl}/api/posUser/roles`,
            constantService.LS_POS_ROLES_KEY
        );
    }
}
