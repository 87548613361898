import { AbstractControl } from "@angular/forms";
export class PasswordValidation {

    static MatchPassword(ac: AbstractControl) {
        const password = ac.get("password").value; // to get value in input tag
        const confirmPassword = ac.get("confirmPassword").value; // to get value in input tag
        if (password !== confirmPassword) {
            ac.get("confirmPassword").setErrors({ MatchPassword: true });
        } else {
            return null;
        }
    }
}
