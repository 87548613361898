import { Component, OnInit } from "@angular/core";
import {FadeZoomInTop} from "../../animations/fade-zoom-in-top.decorator";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styles: []
})
export class AuthLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
