import { Injectable } from "@angular/core";
import { Config } from "app/config/config.service";
import { map } from "rxjs/operators";

import { ConstantsService } from "app/shared/utils/constants.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Currency } from "../data/entities/currency";
import { Observable, Observer, Subscription } from "rxjs";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { SessionStorageService } from "app/shared/utils/session-storage.service";

@Injectable()
export class CurrencyService {
    private cachedTranslatedCurrencies: Currency[] = [];
    private subscriptions: Subscription = new Subscription();
    protected key?: string;
    protected url: string;
    protected httpClient: HttpClient;

    constructor(
        httpClient: HttpClient,
        private sessionStorageService: SessionStorageService,
        config: Config,
        constantsService: ConstantsService,
        private translate: TranslateService,
    ) {
        this.httpClient = httpClient;
        this.key = constantsService.LS_CURRENCIES_KEY
        this.url = `${config.apiUrl}/api/currency`;

        this.subscriptions.add(
            this.translate.onLangChange.subscribe(async (_: LangChangeEvent) => {
                this.cachedTranslatedCurrencies = await this.translateCurrencies(this.cachedTranslatedCurrencies);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    async translateCurrencies(currencies: Currency[]): Promise<Currency[]> {
        for (let currency of currencies) {
            try {
                currency.name = await this.translate.get(`app.shared.currencies.${currency.id.toLowerCase()}`).toPromise();
            } catch (err) {
                console.log(err);
            }
        }

        return currencies;
    }

    public getList(): Observable<Currency[]> {
        return new Observable((observer: Observer<Currency[]>) => {
            this.subscriptions.add(
                this.getCurrencies().subscribe(async (currencies: Currency[]) => {
                    this.cachedTranslatedCurrencies = await this.translateCurrencies(currencies);

                    observer.next(this.cachedTranslatedCurrencies);
                    observer.complete();
                })
            );
        });
    }

    public getCurrency(currencyId: string): Observable<Currency> {
        return new Observable((observer: Observer<Currency>) => {
            this.httpClient.get<Currency>(this.url, {
                params: new HttpParams().set("id", currencyId)
            }).subscribe(async (currency: Currency) => {
                try {
                    currency.name = await this.translate.get(`app.shared.currencies.${currency.id.toLowerCase()}`).toPromise();
                } catch (err) {
                    console.log(err);
                }

                observer.next(currency);
                observer.complete();
            });
        });
    }

    public getCurrencies(): Observable<Currency[]> {
        const values = this.key ? this.sessionStorageService.getItem(this.key) : null;
        if (values) {
            return new Observable(observer => {
                observer.next(JSON.parse(values));
                observer.complete();
            });
        } else {
            return this.httpClient.get<Currency[]>(this.url).pipe(map((data: Currency[]) => {
                if (this.key) {
                    this.sessionStorageService.setItem(this.key, JSON.stringify(data));
                }
                return data;
            }));
        }
    }

}
