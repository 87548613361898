import { Component, OnInit, OnDestroy } from "@angular/core";
import {VersionService} from "app/services/version.service";
import {VersionTypes} from "app/data/enums/versionTypes";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { forkJoin, Subscription } from "rxjs";

@Component({
    selector: "sa-footer",
    templateUrl: "./footer.component.html",
    styles: ["span { padding-right: 20px; }"]
})
export class FooterComponent implements OnInit, OnDestroy {
    private subscriptions: Subscription = new Subscription();

    constructor(
        private versionService: VersionService,
        private translate: TranslateService,
    ) {
    }

    frontendVersionNumber: string;
    apiVersionNumber: string;

    frontendVersionLabel: string;
    apiVersionLabel: string;

    ngOnInit() {
        this.versionService.getVersion(VersionTypes.ui).subscribe(
            result => {
                this.frontendVersionNumber = result.version;
                this._updateTranslations();
            }
        );

        this.versionService.getVersion(VersionTypes.api).subscribe(
            result => {
                this.apiVersionNumber = result.version;
                this._updateTranslations();
            }
        );

        this.subscriptions.add(
            this.translate.onLangChange.subscribe((_: LangChangeEvent) => {
                this._updateTranslations();
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private async _updateTranslations() {
        this.subscriptions.add(
            forkJoin([
                this.translate.get('app.shared.layout.footer.server-version', {server_version: this.frontendVersionNumber}),
                this.translate.get('app.shared.layout.footer.api-version', {api_version: this.apiVersionNumber}),
            ]).subscribe((translations: string[]) => {
                this.frontendVersionLabel = translations[0];
                this.apiVersionLabel = translations[1];
            })
        );
    }

}