import {NgModule} from "@angular/core";
import {FormsModule} from "@angular/forms";

import {CommonModule} from "@angular/common";
import {OnOffSwitchModule} from "./input/on-off-switch/on-off-switch.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        FormsModule, 
        CommonModule,
        TranslateModule,
    ],
    declarations: [],
    exports: [
        OnOffSwitchModule
    ]

})
export class SmartadminFormsLiteModule {
}
