import { Injectable } from "@angular/core";
import { Config } from "app/config/config.service";

import { ClaimSet } from "app/data/entities/claimset";
import { ConstantsService } from "app/shared/utils/constants.service";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "app/shared/utils/local-storage.service";
import { CommonService } from "./common.service";
import { Observable } from "rxjs";
import { Claim } from "app/data/entities/claim";

@Injectable()
export class ClaimsService {
    private agentClaims: CommonService<ClaimSet>;
    private userClaims: CommonService<ClaimSet>;
    private allowedClaims: CommonService<string>;

    public getUserClaims(): Observable<Array<ClaimSet>> {
        return this.userClaims.getList();
    }
    public getAgentClaims(): Observable<Array<ClaimSet>> {
        return this.agentClaims.getList();
    }
    public getAllowedClaims(): Observable<Array<string>> {
        return this.allowedClaims.getList();
    }


    protected getLocalStorageKey(): string {
        return this.constantsService.LS_COUNTRIES_KEY;
    }

    constructor(private httpClient: HttpClient,
        private config: Config,
        private constantsService: ConstantsService) {
        this.userClaims = new CommonService<ClaimSet>(
            httpClient, null, `${config.apiUrl}/api/claim/user`);
        this.agentClaims = new CommonService<ClaimSet>(
            httpClient, null, `${config.apiUrl}/api/claim/agent`);
        this.allowedClaims = new CommonService<string>(
            httpClient, null, `${config.apiUrl}/api/claim/allowed`);
    }
}
