import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NgModule} from "@angular/core";

import {CollapseMenuComponent} from "./collapse-menu/collapse-menu.component";
import {FullScreenComponent} from "./full-screen/full-screen.component";

import {HeaderComponent} from "./header.component";

import {UtilsModule} from "../../utils/utils.module";
import {UserModule} from "../../user/user.module";
import {BsDropdownModule} from "ngx-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule } from "../../../material.module";
import { AppLangSwitcherModule } from "./app-lang-switcher/app-lang-switcher.module";


@NgModule({
  imports: [
    CommonModule,

    FormsModule,
    BsDropdownModule,

    UtilsModule, 
    UserModule,

    TranslateModule,
    MaterialModule,
    
    AppLangSwitcherModule,
  ],
  declarations: [
    FullScreenComponent,
    CollapseMenuComponent,
    HeaderComponent,
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule{}
