﻿import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-input",
    templateUrl: "./input.component.html",
    styleUrls: ["./input.component.css"]
})
export class InputComponent<T> {

    public showMessage = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        public dialogRef: MatDialogRef<InputComponent<T>>
    ) { }

    public checkBonus(code: string) {
        if (code) {
            this.dialogRef.close(code);
        } else {
            this.showMessage = true;
        }
    }
}
