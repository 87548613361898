
import {
    Component,
    Input,
    OnInit,
    OnDestroy,
    ChangeDetectorRef
} from "@angular/core";
import { Subscription, Subject } from "rxjs";
import { Config } from "app/config/config.service";
import { SimpleDataSource } from "app/data/datasets/simple.datasource";
import { FormatHelper } from "app/shared/utils/format-helper";
import { RowIterator } from "app/data/row-iterator";
import { TranslateService } from "@ngx-translate/core";
import { SharedReportDetail } from "app/data/entities/shared-report-detail";
import { ReportService } from "app/services/report.service";
import { GameFamily35Detail } from "app/data/entities/gameFamily35-detail";
import { GameFamily35Hand } from "app/data/entities/gameFamily35-hand";
import { GameFamily35Spot } from "app/data/entities/gameFamily35-spot";
import { PlayingCardComponent } from "app/generic-list/custom-actions/player-game-actions/playing-card.component";
import { PlayerGameService } from "../player-game.service";
import { DataSource } from "@angular/cdk/collections";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "app-gamefamily35-details",
    templateUrl: "gamefamily35-details.component.html",
    styleUrls: ["gamefamily35-details.component.css"]
})
export class GameFamily35DetailsComponent implements OnInit, OnDestroy {

    @Input() set rowData(value: RowIterator) {
        if (value) {
            this._rowData = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    @Input() set tokenhash(value: string) {
        if (value) {
            this._token = value;
        }
    }

    @Input() set datahash(value: string) {
        if (value) {
            this._encodedId = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    public errorCode: string = "";
    public bShowMessageError: boolean = false;
    public playerBets: Subject<GameFamily35Detail[]>;
    public spots: Subject<GameFamily35Spot[]>;
    public hands: Subject<GameFamily35Hand[]>;
    public sharedReportDetails: SharedReportDetail;
    public helpFileUrl: string;
    public separatedCards: { handDescription: string; card: string[]; total?: number; }[] = [];
    public currencysymbol: string;

    private _rowData: RowIterator;
    private _encodedId: string;
    private _token: string;

    private subscriptions: Subscription = new Subscription();
    private currency: string;

    private playerGameServiceSubscription: Subscription;
    public isLoading: boolean;
    public currencyClassification: string;

    public isGlobalGameDetail: boolean = false;
    public globalGameDetailUrl: SafeResourceUrl;

    readonly IN_CASINO_GAMES_HISTORY_REPORT: number = 0;
    readonly GAMES_HISTORY_REPORT_GLOBAL_SERVICE: number = 1;

    constructor(
        private reportService: ReportService,
        private config: Config,
        private playerGameService: PlayerGameService, 
        private cd: ChangeDetectorRef,
        private translate: TranslateService,
        private sanitizer: DomSanitizer
    ) { 
        this.isGlobalGameDetail = false;
        const url = "";
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

        this.playerGameServiceSubscription = this.playerGameService.refresh$.subscribe(() => {
            if (this._rowData.currentRow.externalGameId === Number(this.config.gameDetailsReport.gameFamily35)){
                this.isGlobalGameDetail = false;
                this.getGameData();
            }
        });
        this.hands = new Subject();
        this.spots = new Subject();
        this.playerBets = new Subject();        
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.playerGameServiceSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    get row(): any {
        if (this._rowData) {
            return this._rowData.currentRow;
        }
        return;
    }

    get handsDatasource(): DataSource<GameFamily35Hand> {
        return new SimpleDataSource(this.hands.asObservable());
    }

    get spotsDatasource(): DataSource<GameFamily35Spot> {
        return new SimpleDataSource(this.spots.asObservable());
    }

    get betsDatasource(): DataSource<GameFamily35Detail> {
        return new SimpleDataSource(this.playerBets.asObservable());
    }

    getGameData() {
        const gameFamily35Detail = (this._rowData) ?
            this.reportService.getGameFamily35Detail(this.row.idStr) :
            this.reportService.getGameFamily35DetailEncoded(this._encodedId, this._token);

        this.isLoading = true;

        gameFamily35Detail.subscribe((gameFamily35Detail: GameFamily35Detail) => {
            if (this.translate.currentLang === undefined) {
                this.translate.currentLang = gameFamily35Detail.languagecode ? gameFamily35Detail.languagecode : "en";
                this.translate.setDefaultLang(this.translate.currentLang);
            }
            this.spots.next(gameFamily35Detail.spots);
            this.playerBets.next([gameFamily35Detail]);
            this.hands.next(gameFamily35Detail.hands);
            this.currency = gameFamily35Detail.currency;
            this.helpFileUrl = gameFamily35Detail.helpFile;
            this.currencysymbol = gameFamily35Detail.currencySymbol;            
            this.separatedCards = gameFamily35Detail.hands.filter(player => player.card).map(player => ({ handDescription: player.handDescription, card: player.card.split(','), total: 0 }));

            // 32 Cards
            if (gameFamily35Detail.externalgameId === 1) {
                for (const playerHand of this.separatedCards) {
                    let cardsSum = 0;
                    for (const cardStr of playerHand.card) {
                        const card = this.parseCardString(cardStr);
                        if (card.rank !== "A") {
                            const rankValue = PlayingCardComponent.ranks.indexOf(card.rank) + 1;
                            playerHand.total += rankValue;
                            cardsSum += rankValue;
                        }
                        else {
                            const rankValue = 14;
                            playerHand.total += rankValue;
                            cardsSum += rankValue;
                        }
                    }
                    playerHand.total += parseInt(playerHand.handDescription.split(" ")[1]);
                }
            }

            this.sharedReportDetails = new SharedReportDetail(
                gameFamily35Detail.gameName,
                gameFamily35Detail.login,
                gameFamily35Detail.betid,
                gameFamily35Detail.currency,
                gameFamily35Detail.totalBet,
                gameFamily35Detail.totalPayout,
                gameFamily35Detail.createDate,
                gameFamily35Detail.roundId
            );            
            this.currencyClassification = gameFamily35Detail.currencyClassification;
            this.getReportUrl(gameFamily35Detail);

            this.isLoading = false;
        },
            err => {
                this.translate.currentLang = "en";
                this.translate.setDefaultLang(this.translate.currentLang);
                this.bShowMessageError = true;
                this.errorCode = err.status.toString();
                this.cd.detectChanges();
            });
        this.cd.markForCheck();
    }

    public getReportUrl(detail: GameFamily35Detail): void {
        this.isGlobalGameDetail = (detail.gamesHistoryReportProviderId === this.GAMES_HISTORY_REPORT_GLOBAL_SERVICE ? true : 
            (detail.gamesHistoryReportProviderId === this.IN_CASINO_GAMES_HISTORY_REPORT ? false : false));
        const url = detail.globalGameDetailsUrl;
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public showColumn(): boolean {
        return this.separatedCards.some(player => player.total !== 0);
    }

    public getImgUrl(url: string): string {
        return this.config.imageCdnUrl + url;
    }

    public parseCardString(cardStr: string) {
        const suit = cardStr.charAt(cardStr.length - 1);
        const rank = cardStr.substring(0, cardStr.length - 1);
        return { suit, rank };
    }

    public validateStructure(): boolean {
        for (let item of this.separatedCards) {
            if (item.card.length !== 1) {
                return false;
            }
        }
        return true;
    }

    public getPayout(amount: number): string {
        return FormatHelper.currency(amount, this.currency, this.translate.currentLang, this.currencyClassification);
    }

    public getMessageError(error: string): string {
        this.bShowMessageError = true;
        if (error) {
            switch (error) {
                case "400":
                    return "app.shared.labels.tokenExpired";
                case "404":
                    return 'app.casinos.generic-list.error-no-data';
                default:
                    return "app.auth.login.error-try-again-later";
            }
        }
    }
}
