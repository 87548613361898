import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from "@ngx-translate/core";
import { MaterialModule} from "app/material.module";

import { BlackjackDetailsComponent } from "app/generic-list/custom-actions/player-game-actions/blackjack/blackjack-details.component";
import { PlayingCardStackComponent } from "app/generic-list/custom-actions/player-game-actions/playing-card-stack.component";
import { PlayingCardComponent } from "app/generic-list/custom-actions/player-game-actions/playing-card.component";
import { TriCardPokerDetailsComponent } from "app/generic-list/custom-actions/player-game-actions/tri-card-poker/tricardpoker-details.component";
import { KenoDetailsComponent } from "app/generic-list/custom-actions/player-game-actions/keno/keno-details.component";
import { RouletteDetailsComponent } from "app/generic-list/custom-actions/player-game-actions/roulette/roulette-details.component";
import { VPokerDetailsComponent } from "app/generic-list/custom-actions/player-game-actions/video-poker/vpoker-details.component";
import { GameFamily35DetailsComponent } from 'app/generic-list/custom-actions/player-game-actions/gamefamily35/gamefamily35-details.component';
import { GameFamily36DetailsComponent } from 'app/generic-list/custom-actions/player-game-actions/gamefamily36/gamefamily36-details.component';

@NgModule({
 imports: [ 
     CommonModule,
     TranslateModule,
     MaterialModule
     ],
 declarations: [ 
     BlackjackDetailsComponent, 
     PlayingCardStackComponent, 
     PlayingCardComponent,
     TriCardPokerDetailsComponent, 
     KenoDetailsComponent, 
     RouletteDetailsComponent, 
     VPokerDetailsComponent,
     GameFamily35DetailsComponent,
     GameFamily36DetailsComponent
],
 exports: [ 
     BlackjackDetailsComponent, 
     PlayingCardStackComponent, 
     PlayingCardComponent, 
     TriCardPokerDetailsComponent, 
     KenoDetailsComponent, 
     RouletteDetailsComponent, 
     VPokerDetailsComponent,
     GameFamily35DetailsComponent,
     GameFamily36DetailsComponent
    ]
})
export class SharedReportsModule { }