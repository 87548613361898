
import {map} from "rxjs/operators";
import { Response } from "@angular/http";
import { Observable, of } from "rxjs";
import { GenericDto, ITabularDataService } from "app/data/datasets/generic.datasourceserverside";
import { Injectable } from "@angular/core";
import { TokenManager } from "../shared/utils/token-manager";
import { Config } from "../config/config.service";
import { TabularDataParams } from "app/data/entities/tabulardata";
import { HttpClient } from "@angular/common/http";
import { Player } from "../data/entities/player";

@Injectable()
export class PlayerService implements ITabularDataService<any> {

    private claimAgentId: String;
    private readonly emptyGuid = '00000000-0000-0000-0000-000000000000';

    private baseUrl = `${this.config.casinoManagementServiceUrl}/api/player`;

    constructor(private http: HttpClient,
        private config: Config,
        private tokenManager: TokenManager) {
    }

    getData(url: string, reportParams: TabularDataParams): Observable<GenericDto<any>> {
        const response = this.http.post(this.baseUrl + url, reportParams).pipe(
            map((result: Response) => this.readApiResult(result)));
        return response;
    }

    private readApiResult(result: any): GenericDto<any> {
        return result as GenericDto<any>;
    }

    public updatePlayer(player: Player): Observable<any> {
        return this.http.patch(`${this.baseUrl}`, player, { responseType: "text" });
    }

    public deletePlayer(id: string) {
        return this.http.delete(`${this.baseUrl}/${id}`, { responseType: "text" });
    }

    public logoutPlayer(id: string) {
        return this.http.post(`${this.baseUrl}/logout/${id}`, { responseType: "text" });
    }

    public createPlayer(player: Player): Observable<any> {
        return this.http.put(this.baseUrl, player, { responseType: "text" });
    }

    public getPlayer(id: string): Observable<Player> {
        return this.http.get<Player>(`${this.baseUrl}/${id}`);
    }

    public isNameAvailable(id: string, name: string, agentId: string): Observable<any> {
        return this.http.get<any>(
            `${this.baseUrl}/check/${encodeURIComponent(agentId)}/${encodeURIComponent(name)}/${encodeURIComponent(id || this.emptyGuid)}`);
    }

    public redeemBonus(id: string, bonusCode: string): Observable<any> {
        return this.http.post(`${this.baseUrl}/bonus?playerId=${encodeURIComponent(id)}&bonusCode=${encodeURIComponent(bonusCode)}`,
        { responseType: "text" });
    }

    public getActiveBonus(id: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/bonus?playerId=${encodeURIComponent(id)}`);
    }

    public removeBonus(id: string, bonusCode: string): Observable<any> {
        return this.http.delete(`${this.baseUrl}/bonus?playerId=${encodeURIComponent(id)}&bonusCode=${encodeURIComponent(bonusCode)}`,
        { responseType: "text" });
    }
}
