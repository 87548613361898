import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationOptionsComponent } from './navigation-options.component';
import { RouterModule } from "@angular/router";
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
  ],
  declarations: [
    NavigationOptionsComponent
  ],
  exports: [
    NavigationOptionsComponent
  ],
})
export class NavigationOptionsModule {}