import { Injectable } from "@angular/core";
import { IPosPermissionKeys } from "app/data/entities/pos-permission-keys";
import { IGameDetailsReport } from "app/data/entities/gameDetailsReport";

export class Config {
    apiUrl: string;
    localizationServiceUrl: string;
    identityServiceUrl: string;
    casinoManagementServiceUrl: string;
    reportServiceUrl: string;
    adjustCreditQuickAddOptions: string;
    defaultTimeZoneId: number;
    defaultCountryId: string;
    apiTimeOut: number;
    creditBalanceUpdateInterval: number;
    defaultCurrency: string;
    posPermissionKeys: IPosPermissionKeys;
    imageCdnUrl: string;
    gameDetailsReport: IGameDetailsReport;
    weakCurrencyList: string;
}


@Injectable()
export class ConfigService {
    private static config: Config = null;

    public static loadInstance() {
        const jsonFile = "config.json";
        return new Promise((resolve, reject) => {
            const xobj = new XMLHttpRequest();
            xobj.overrideMimeType("application/json");
            xobj.open("GET", jsonFile, true);
            xobj.onreadystatechange = () => {
                if (xobj.readyState === 4) {
                    if (xobj.status === 200) {
                        this.config = JSON.parse(xobj.responseText);
                        resolve();
                    } else {
                        reject(`Could not load file '${jsonFile}': ${xobj.status}`);
                    }
                }
            };
            xobj.send(null);
        });
    }

    public static get(): Config {
        if (ConfigService.config != null) {
            return ConfigService.config;
        }
        throw new Error(`Could not find config, did you load it?`);
    }
}
