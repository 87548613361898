import {of as observableOf,  Observable, Subscribable, Subscription } from "rxjs";

import { Injectable, OnDestroy } from "@angular/core";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { AppLanguage } from "../data/entities/appLanguage";
import { LocalStorageService } from "../shared/utils/local-storage.service";
import { ConstantsService } from "../shared/utils/constants.service";
import { DateAdapter } from "@angular/material";


@Injectable()
export class AppLanguageService implements OnDestroy {
    private languages: AppLanguage[] =  [
        {name: "English", id: "en", imgSrc: "assets/img/app-lang-flags/en.png"}, 
        {name: "Chinese", id: "zh-cn", imgSrc: "assets/img/app-lang-flags/zh-cn.png"},
    ];
    private defaultLangId: string = this.languages[0].id;
    private subscriptions: Subscription = new Subscription();

    constructor(
        private localStorageService: LocalStorageService,
        private translate: TranslateService,
        private constantsService: ConstantsService,
        private dateAdapter: DateAdapter<any>
    ) {
        translate.addLangs(this.languages.map((lang: AppLanguage) => lang.id));
        translate.setDefaultLang(this.defaultLangId);
        this.dateAdapter.setLocale(this.defaultLangId);

        translate.use(this.getCurrentAppLanguageId());

        this.subscriptions.add(
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this._updateTranslations();
                this.dateAdapter.setLocale(event.lang);
                this.setCurrentAppLanguageId(event.lang);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private async _updateTranslations() {
        if (this.languages != null) {
            for (let lang of this.languages) {
                lang.name = await this.translate.get(`app.shared.app-languages.${lang.id.toLowerCase()}`).toPromise();
            }
        }
    }

    private getCurrentAppLanguageId(): string {
        const appLanguageSettings = this.localStorageService.getItem(this.constantsService.LS_APP_LANGUAGE_SETTINGS_KEY);

        if (appLanguageSettings) {
            return JSON.parse(appLanguageSettings).currentLangId;
        }

        return this.defaultLangId;
    }

    private setCurrentAppLanguageId(langId: string) {
        this.localStorageService.setItem(this.constantsService.LS_APP_LANGUAGE_SETTINGS_KEY, 
            JSON.stringify({currentLangId: langId}));
    }

    getAppLanguages(): AppLanguage[] {
        return this.languages;
    }

    getCurrentAppLang() {
        for (let lang of this.languages) {
            if (lang.id === this.translate.currentLang) {
                return lang;
            }
        }

        return null;
    }
}