// This file has been genereated by ClaimsGenerator tool! Don't change directly!
export class Claims {
public static CanViewCasinos = "CanViewCasinos";
public static CanCreateCasino = "CanCreateCasino";
public static CanUpdateCasino = "CanUpdateCasino";
public static CanDeleteCasino = "CanDeleteCasino";
public static CanViewCasinoAssignment = "CanViewCasinoAssignment";
public static CanEditCasinoAssignment = "CanEditCasinoAssignment";
public static CanCreatePlayers = "CanCreatePlayers";
public static CanListPlayers = "CanListPlayers";
public static CanEditPlayers = "CanEditPlayers";
public static CanDeletePlayers = "CanDeletePlayers";
public static CanLogoutPlayers = "CanLogoutPlayers";
public static CanDepositToWallet = "CanDepositToWallet";
public static CanWithdrawFromWallet = "CanWithdrawFromWallet";
public static CanSeeWalletBalance = "CanSeeWalletBalance";
public static CanLaunchGames = "CanLaunchGames";
public static CanLaunchLobby = "CanLaunchLobby";
public static CanCreateGames = "CanCreateGames";
public static CanListGames = "CanListGames";
public static CanEditGames = "CanEditGames";
public static CanDeleteGames = "CanDeleteGames";
public static CanView2ndPlusLevelChildren = "CanView2ndPlusLevelChildren";
public static CanEdit2ndPlusLevelChildren = "CanEdit2ndPlusLevelChildren";
public static CanListUsers = "CanListUsers";
public static CanRequestUserDetails = "CanRequestUserDetails";
public static CanCreateUsers = "CanCreateUsers";
public static CanDeleteUsers = "CanDeleteUsers";
public static CanEditUsers = "CanEditUsers";
public static CanSeeUserClaims = "CanSeeUserClaims";
public static CanEditUserClaims = "CanEditUserClaims";
public static CanEditUserRoles = "CanEditUserRoles";
public static CanViewUserRoles = "CanViewUserRoles";
public static CanViewAgent = "CanViewAgent";
public static CanAddAgent = "CanAddAgent";
public static CanEditAgent = "CanEditAgent";
public static CanSetCreditSource = "CanSetCreditSource";
public static CanManageJackpotAccount = "CanManageJackpotAccount";
public static CanDeleteAgent = "CanDeleteAgent";
public static CanViewTerminals = "CanViewTerminals";
public static CanViewTerminalTransaction = "CanViewTerminalTransaction";
public static CanCreditAgent = "CanCreditAgent";
public static CanRemoveAgentsCredits = "CanRemoveAgentsCredits";
public static CanViewPointOfSales = "CanViewPointOfSales";
public static CanCreatePointOfSales = "CanCreatePointOfSales";
public static CanEditPointOfSales = "CanEditPointOfSales";
public static CanDeletePointOfSales = "CanDeletePointOfSales";
public static CanCreditPointOfSale = "CanCreditPointOfSale";
public static CanRemovePointofSalesCredits = "CanRemovePointofSalesCredits";
public static CanViewPointOfSalesUsers = "CanViewPointOfSalesUsers";
public static CanAddPointOfSalesUsers = "CanAddPointOfSalesUsers";
public static CanEditPointOfSalesUsers = "CanEditPointOfSalesUsers";
public static CanChangePasswordOfPointOfSalesUsers = "CanChangePasswordOfPointOfSalesUsers";
public static CanDeletePermanently = "CanDeletePermanently";
public static CanRequestCurrencyExchange = "CanRequestCurrencyExchange";

public static CanViewIpWhitelistRules = "CanViewIpWhitelistRules";
public static CanEditIpWhitelistRules = "CanEditIpWhitelistRules";
public static CanRequestExtraInfoOfAgents = "CanRequestExtraInfoOfAgents";
public static CanViewPlayerSessions = "CanViewPlayerSessions";
public static CanViewAgentSummary = "CanViewAgentSummary";
public static CanViewCreditTransfer = "CanViewCreditTransfer";
public static CanViewGameHistory = "CanViewGameHistory";
public static CanViewBalanceAdjustments = "CanViewBalanceAdjustments";
public static CanViewCafeSummary = "CanViewCafeSummary";
public static CanViewPlayerGame = "CanViewPlayerGame";
public static CanViewGamePerformance = "CanViewGamePerformance";
public static CanViewCasinoPerformance = "CanViewCasinoPerformance";
public static CanViewPlayerPerformance = "CanViewPlayerPerformance";

public static CanViewSideBets = "CanViewSideBets";
public static CanViewOptIn = "CanViewOptIn";
public static CanViewGameActivity = "CanViewGameActivity";
public static CanViewWalletEndpoints = "CanViewWalletEndpoints";
public static CanUpdateWalletEndpoints = "CanUpdateWalletEndpoints";
public static CanCreateWalletEndpoints = "CanCreateWalletEndpoints";
public static CanViewWalletAgent = "CanViewWalletAgent";
public static CanUpdateWalletAgent = "CanUpdateWalletAgent";
public static CanCreateWalletAgent = "CanCreateWalletAgent";
public static CanRedeemBonus = "CanRedeemBonus";
public static CanDenyBonus = "CanDenyBonus";
public static CanViewBonusInformation = "CanViewBonusInformation";
public static CanCdnAgentConfiguration = "CanCdnAgentConfiguration";
public static CanListSidebetOperators = "CanListSidebetOperators";
public static CanViewSidebetOperator = "CanViewSidebetOperator";
public static CanCreateSidebetOperator = "CanCreateSidebetOperator";
public static CanUpdateSidebetOperator = "CanUpdateSidebetOperator";
public static CanDeleteSidebetOperator = "CanDeleteSidebetOperator";
public static CanViewJackpotHistory = "CanViewJackpotHistory";
public static CanExportToCSV = "CanExportToCSV";
public static CanViewDashboard = "CanViewDashboard";
public static CanViewTournamentWinners = "CanViewTournamentWinners";
public static CanViewPendingGames = "CanViewPendingGames";
public static CanClosePendingGames = "CanClosePendingGames";
public static CanCloseCasinoPendingGames = "CanCloseCasinoPendingGames";
public static CanWipePointOfSales = "CanWipePointOfSales";
}
export class ClaimValues {
public static CanViewCasinos = "Can View Casinos";
public static CanCreateCasino = "Can Create Casino";
public static CanUpdateCasino = "Can Update Casino";
public static CanDeleteCasino = "Can Delete Casino";
public static CanViewCasinoAssignment = "Can View Casino Assignment";
public static CanEditCasinoAssignment = "Can Edit Casino Assignment";
public static CanCreatePlayers = "Can Create Players";
public static CanListPlayers = "Can List Players";
public static CanEditPlayers = "Can Edit Players";
public static CanDeletePlayers = "Can Delete Players";
public static CanLogoutPlayers = "Can Logout Players";
public static CanDepositToWallet = "Can Deposit to Player's Wallet";
public static CanWithdrawFromWallet = "Can Withdraw from Player's Wallet";
public static CanSeeWalletBalance = "Can See Player's Balance";
public static CanLaunchGames = "Can Launch Games";
public static CanLaunchLobby = "Can Launch Lobby";
public static CanCreateGames = "Can Create Games";
public static CanListGames = "Can List Games";
public static CanEditGames = "Can Edit Games";
public static CanDeleteGames = "Can Delete Games";
public static CanView2ndPlusLevelChildren = "Can View 2nd Plus Level Children";
public static CanEdit2ndPlusLevelChildren = "Can Edit 2nd Plus Level Children";
public static CanListUsers = "Can List Users";
public static CanRequestUserDetails = "Can Request User Details";
public static CanCreateUsers = "Can Create Users";
public static CanDeleteUsers = "Can Delete Users";
public static CanEditUsers = "Can Edit Users";
public static CanSeeUserClaims = "Can See User Claims";
public static CanEditUserClaims = "Can Edit User Claims";
public static CanEditUserRoles = "Can Edit User Roles";
public static CanViewUserRoles = "Can View User Roles";
public static CanViewAgent = "Can View Agent";
public static CanAddAgent = "Can Add Agent";
public static CanEditAgent = "Can Edit Agent";
public static CanSetCreditSource = "Can Set Credit Source";
public static CanManageJackpotAccount = "Can Manage Jackpot Account";
public static CanDeleteAgent = "Can Delete Agent";
public static CanViewTerminals = "Can View Terminals";
public static CanViewTerminalTransaction = "Can View Terminal Transaction";
public static CanCreditAgent = "Can Credit Agent";
public static CanRemoveAgentsCredits = "Can Remove Agents Credits";
public static CanViewPointOfSales = "Can View Point Of Sales";
public static CanCreatePointOfSales = "Can Create Point Of Sales";
public static CanEditPointOfSales = "Can Edit Point Of Sales";
public static CanDeletePointOfSales = "Can Delete Point Of Sales";
public static CanCreditPointOfSale = "Can Credit Point Of Sale";
public static CanRemovePointofSalesCredits = "Can Remove Point Of Sales Credits";
public static CanViewPointOfSalesUsers = "Can View Point Of Sales Users";
public static CanAddPointOfSalesUsers = "Can Add Point Of Sales Users";
public static CanEditPointOfSalesUsers = "Can Edit Point Of Sales Users";
public static CanChangePasswordOfPointOfSalesUsers = "Can Change Password Of Point Of Sales Users";
public static CanDeletePermanently = "Can Delete Permanently";
public static CanRequestCurrencyExchange = "Can Request Currency Exchange Rates";

public static CanViewIpWhitelistRules = "Can View IP Whitelist Rules";
public static CanEditIpWhitelistRules = "Can Edit IP Whitelist Rules";
public static CanRequestExtraInfoOfAgents = "Can Request Extra Info of Master Agents";
public static CanViewPlayerSessions = "Can View Player Sessions";
public static CanViewAgentSummary = "Can View Agent Summary";
public static CanViewCreditTransfer = "Can View Credit Transfer";
public static CanViewGameHistory = "Can View Game History";
public static CanViewBalanceAdjustments = "Can View Balance Adjustments";
public static CanViewCafeSummary = "Can View Cafe Summary";
public static CanViewPlayerGame = "Can View Player Game";
public static CanViewGamePerformance = "Can View Game Performance";
public static CanViewCasinoPerformance = "Can View Casino Performance";
public static CanViewPlayerPerformance = "Can View Player Performance";

public static CanViewSideBets = "Can View Side Bets";
public static CanViewOptIn = "Can View Opt In";
public static CanViewGameActivity = "Can View Game Activity";
public static CanViewWalletEndpoints = "CanViewWalletEndpoints";
public static CanUpdateWalletEndpoints = "CanUpdateWalletEndpoints";
public static CanCreateWalletEndpoints = "CanCreateWalletEndpoints";
public static CanViewWalletAgent = "CanViewWalletAgent";
public static CanUpdateWalletAgent = "CanUpdateWalletAgent";
public static CanCreateWalletAgent = "CanCreateWalletAgent";
public static CanRedeemBonus = "Can Redeem Bonus";
public static CanDenyBonus = "Can Deny Bonus";
public static CanViewBonusInformation = "Can View Bonus Information";
public static CanCdnAgentConfiguration = "Cdn Agent Configuration";
public static CanListSidebetOperators = "Can List Side bet Operators";
public static CanViewSidebetOperator = "Can View Side bet Operators";
public static CanCreateSidebetOperator = "Can Create Side bet Operators";
public static CanUpdateSidebetOperator = "Can Update Side bet Operators";
public static CanDeleteSidebetOperator = "Can Delete Side bet Operators";
public static CanViewJackpotHistory = "Can View Jackpot History";
public static CanExportToCSV = "CanExportToCSV";
public static CanViewDashboard = "CanViewDashboard";
public static CanViewTournamentWinners = "CanViewTournamentWinners";
public static CanViewPendingGames = "Can View Pending Games";
public static CanClosePendingGames = "Can Close Pending Games";
public static CanCloseCasinoPendingGames = "Can Close Casino Pending Games";
public static CanWipePointOfSales = "Can Wipe Point Of Sales";

}
export class ClaimTypes {
public static AgentClaims = "AgentClaims";
public static RtgClaims = "RtgClaims";
public static RtgGroupClaims = "RtgGroupClaims";
}
export class ClaimGroups {
public static Main = [
Claims.CanViewCasinos,
Claims.CanCreateCasino,
Claims.CanUpdateCasino,
Claims.CanDeleteCasino,
Claims.CanViewCasinoAssignment,
Claims.CanEditCasinoAssignment,
Claims.CanCreatePlayers,
Claims.CanListPlayers,
Claims.CanEditPlayers,
Claims.CanDeletePlayers,
Claims.CanLogoutPlayers,
Claims.CanDepositToWallet,
Claims.CanWithdrawFromWallet,
Claims.CanSeeWalletBalance,
Claims.CanLaunchGames,
Claims.CanLaunchLobby,
Claims.CanCreateGames,
Claims.CanListGames,
Claims.CanEditGames,
Claims.CanDeleteGames,
Claims.CanView2ndPlusLevelChildren,
Claims.CanEdit2ndPlusLevelChildren,
Claims.CanListUsers,
Claims.CanRequestUserDetails,
Claims.CanCreateUsers,
Claims.CanDeleteUsers,
Claims.CanEditUsers,
Claims.CanSeeUserClaims,
Claims.CanEditUserClaims,
Claims.CanEditUserRoles,
Claims.CanViewUserRoles,
Claims.CanViewAgent,
Claims.CanAddAgent,
Claims.CanEditAgent,
Claims.CanSetCreditSource,
Claims.CanManageJackpotAccount,
Claims.CanDeleteAgent,
Claims.CanViewTerminals,
Claims.CanViewTerminalTransaction,
Claims.CanCreditAgent,
Claims.CanRemoveAgentsCredits,
Claims.CanViewPointOfSales,
Claims.CanCreatePointOfSales,
Claims.CanEditPointOfSales,
Claims.CanDeletePointOfSales,
Claims.CanCreditPointOfSale,
Claims.CanRemovePointofSalesCredits,
Claims.CanViewPointOfSalesUsers,
Claims.CanAddPointOfSalesUsers,
Claims.CanEditPointOfSalesUsers,
Claims.CanChangePasswordOfPointOfSalesUsers,
Claims.CanDeletePermanently,
Claims.CanRequestCurrencyExchange,

Claims.CanViewIpWhitelistRules,
Claims.CanEditIpWhitelistRules,
Claims.CanRequestExtraInfoOfAgents,
Claims.CanViewWalletEndpoints,
Claims.CanUpdateWalletEndpoints,
Claims.CanCreateWalletEndpoints,
Claims.CanViewWalletAgent,
Claims.CanUpdateWalletAgent,
Claims.CanCreateWalletAgent, 
Claims.CanRedeemBonus,
Claims.CanDenyBonus,
Claims.CanViewBonusInformation,
Claims.CanCdnAgentConfiguration,
Claims.CanListSidebetOperators,
Claims.CanViewSidebetOperator,
Claims.CanCreateSidebetOperator,
Claims.CanUpdateSidebetOperator,
Claims.CanDeleteSidebetOperator,
Claims.CanClosePendingGames,
Claims.CanCloseCasinoPendingGames,
Claims.CanWipePointOfSales
];
public static Reports = [
Claims.CanViewPlayerSessions,
Claims.CanViewAgentSummary,
Claims.CanViewCreditTransfer,
Claims.CanViewGameHistory,
Claims.CanViewBalanceAdjustments,
Claims.CanViewCafeSummary,
Claims.CanViewPlayerGame,
Claims.CanViewGamePerformance,
Claims.CanViewCasinoPerformance,
Claims.CanViewPlayerPerformance,
Claims.CanViewSideBets,
Claims.CanViewOptIn,
Claims.CanViewGameActivity,
Claims.CanViewJackpotHistory,
Claims.CanExportToCSV,
Claims.CanViewDashboard,
Claims.CanViewTournamentWinners,
Claims.CanViewPendingGames
];
}
