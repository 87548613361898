import { Component, OnInit } from "@angular/core";
import {LayoutService} from "../layout.service";
import { Router } from "@angular/router";
import { BroadcasterService } from "app/shared/utils/broadcaster.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "sa-ribbon",
  templateUrl: "./ribbon.component.html",
  styles: [
    `.animate {
        -webkit-animation:spin 1s linear infinite;
        -moz-animation:spin 1s linear infinite;
        animation:spin 1s linear infinite;
  }`]
})
export class RibbonComponent implements OnInit {
  animate: boolean;
  public reloadTimmer: any;

  constructor(
    private layoutService: LayoutService,
    private broadcasterService: BroadcasterService,
    private router: Router,
    private translate: TranslateService,
  ) {}

  ngOnInit() {
    this.broadcasterService.on("refreshComponentDone").subscribe(
      () => {
        clearTimeout(this.reloadTimmer);
      }
    );
  }

  resetWidgets() {
    this.layoutService.factoryReset();
  }

  refreshPage() {
    this.animate = true;
    setTimeout( () => this.animate = false, 450);
    this.reloadTimmer = setTimeout( () => location.reload(), 500);
    this.broadcasterService.broadcast("refreshComponent");
  }
}
