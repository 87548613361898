import { Injectable } from "@angular/core";

@Injectable()
export class SessionStorageService {
    /* 
        Similar to LocalStorage service.
        Should be used for persisting sensitive data (e.g. auth tokens)
     */

    constructor() {
    }

    public setItem(key: string, value: string) {
        if (window.sessionStorage) {
            sessionStorage.setItem(key, value);
        } else {
            // Todo: Implement fallback.
        }
    }

    public removeItem(key: string): void {
        if (window.sessionStorage) {
            sessionStorage.removeItem(key);
        }
    }

    public getItem(key: string): string {
        if (window.sessionStorage) {
            return sessionStorage.getItem(key);
        } else {
            // Todo: Implement fallback.
            return "";
        }
    }
}
