import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-dialog",
    templateUrl: "./dialog.component.html",
    styleUrls: ["./dialog.component.css"]
})
export class DialogComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
    ) { }

}
