export class RowIterator {
    collection: Array<any>;
    currentIndex: number;
    constructor(collection: Array<any>, currentIndex: number) {
        this.collection = collection;
        this.currentIndex = currentIndex;
    }
    get currentRow(): any {
        return this.collection[this.currentIndex];
    }
    get first(): boolean {
        return this.currentIndex === 0;
    }
    get last(): boolean {
        return this.currentIndex === this.collection.length - 1;
    }
    forward() {
        if (!this.last) {
            this.currentIndex++;
        }
    }
    back() {
        if (!this.first) {
            this.currentIndex--;
        }
    }
}