
import {repeat} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { RowIterator } from "app/data/row-iterator";

@Injectable()
export class PlayerGameService {

    // Observable string sources
    private currentRowSource = new Subject<RowIterator>();
    private refreshSource = new Subject();

    // Observable string streams
    currentRow$ = this.currentRowSource.asObservable();
    refresh$ = this.refreshSource.asObservable();

    private _row: RowIterator;
    // Service message commands
    set currentRow(row: RowIterator) {
        this._row = row;
        this.currentRowSource.next(row);
    }

    forward() {
        this._row.forward();
        this.refreshSource.next();
        this.currentRowSource.pipe(repeat());
    }
    back() {
        this._row.back();
        this.refreshSource.next();
        this.currentRowSource.pipe(repeat());
    }
}
