import { Injectable } from "@angular/core";

import { AccountManager } from "app/services/permissions/account-manager";
import { NavigationService } from "app/services/navigation.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { NavigationOptionDefinition } from "../data/entities/navigation-option";


@Injectable()
export class NavigationOptionsService {

    private options: NavigationOptionDefinition[] = [
        {
            name: "Agents", cssClass: "as-icon-Agents", path: "agents", label: "app.shared.labels.agents",
            visible: (agentId: string) => this.accountManager.canViewAgents(agentId)
        },
        {
            name: "Users", cssClass: "as-icon-Users", path: "users", label: "app.shared.labels.users",
            visible: (agentId: string) => this.accountManager.canListUsers(agentId)
        },
        {
            name: "Players", cssClass: "as-icon-Agents", path: "players", label: "app.shared.labels.players",
            visible: (agentId: string) => this.accountManager.canListPlayers(agentId)
        },
        {
            name: "Cafes", cssClass: "as-icon-Pos", path: "poses", label: "app.services.navigation-options.cafes",
            visible: (agentId: string) => this.accountManager.canViewPointOfSales(agentId)
        },
        {
            name: "Terminals", cssClass: "as-icon-Terminals", path: "terminals", label: "app.shared.labels.terminals",
            visible: (agentId: string) => this.accountManager.canViewTerminals(agentId)
        },
        {
            name: "Reports", cssClass: "as-icon-Reports", path: "reports", label: "app.shared.labels.reports",
            visible: (agentId: string) => this.accountManager.canViewAnyReport(agentId)
        },
        {
            name: "Roles", cssClass: "as-icon-Roles", path: "roles", label: "app.shared.labels.roles",
            visible: (agentId: string) => this.accountManager.canViewUserRoles()
        },
        {
            name: "Assigned Casinos", cssClass: "as-icon-Pos", path: "casinos/assigned", label: "app.services.navigation-options.assigned-casinos",
            visible: (agentId: string) => this.accountManager.canViewCasinoAssigment(agentId)
        },
        null,
        {
            name: "Casinos", cssClass: "as-icon-Pos", path: "casinos", label: "app.shared.labels.casinos",
            visible: (agentId: string) => this.accountManager.canViewCasinos(agentId)
        },
        {
            name: "Adjust Credits", cssClass: "as-icon-Adjust-Credit", path: "agents/adjust-credit", label: "app.services.navigation-options.adjust-credits",
            visible: (agentId: string) => this.accountManager.canCreditAgent(agentId) || this.accountManager.canRemoveCredits(agentId) ,
            disabled: (res: boolean) => res
        },
        {
            name: "Create Agent", cssClass: "as-icon-Agents", path: "agents/create", label: "app.services.navigation-options.create-agent",
            visible: (agentId: string) => this.accountManager.canAddAgent(agentId), disabled: false
        },
        {
            name: "Add User", cssClass: "as-icon-Users", path: "users/add", label: "app.services.navigation-options.add-user",
            visible: (agentId: string) => this.accountManager.canCreateUser(agentId)
        },
        {
            name: "Add Casino", cssClass: "as-icon-Pos", path: "casinos/add", label: "app.services.navigation-options.add-casino",
            visible: (agentId: string) => this.accountManager.canCreateCasino(agentId)
        },
    ];

    constructor(
        private accountManager: AccountManager,
        private navigationService: NavigationService,
        private translate: TranslateService,
    ) {
        this._updateTranslations();
        this.translate.onLangChange.subscribe((_: LangChangeEvent) => {
            this._updateTranslations();
        });
    }

    private _updateTranslations() {
        this.options.forEach((option: NavigationOptionDefinition) => {
            if (option == null) {
                return;
            }
            
            this.translate.get(option.label).subscribe((localizedOption: string) => {
                option.name = localizedOption;
            });
        });
    }

    public hasAccessToAnyMainActions(agentId: string): boolean {
        if (this.getLandingRoute(agentId)) {
            return true;
        }
        return false;
    }

    public getLandingRoute(agentId: string): string {
        for (const o of this.options) {
            if (o && o.visible(agentId)) {
                return o.path;
            }
        }

        return "";
    }

    getValidUrlPerAgent(routerUrl: string): string {
        const agentId = this.navigationService.getAgentIdFromUrl(routerUrl);
        const agentCanAccess = this.options.map( (option) => {
            if ( option && option.visible(agentId) ) {
                return option.path;
            }
        });
        const curretMenuItemPath = this.findActivePath(routerUrl);
        if (agentCanAccess.indexOf(curretMenuItemPath) !== -1) {
            return curretMenuItemPath;
        } else if (!agentCanAccess.length) {
            return "/";
        } else {
            return agentCanAccess.shift();
        }
    }

    public findActivePath(url) {
        const currentUrl = url.split("/").splice(2).join("/");
        const optionPaths = this.options.map( (option) => {
            if (option) {
                return option.path;
            }
        });
        let id = -1;
        if (optionPaths.indexOf(currentUrl) > -1) {
            id = optionPaths.indexOf(currentUrl);
        } else if (optionPaths.indexOf(currentUrl.split("/").shift()) !== -1) {
            id = optionPaths.indexOf(currentUrl.split("/").shift());
        }
        return optionPaths[id];
    }

    public getLandingRouteFromUrl(url: string): string {
        return this.getLandingRoute(this.navigationService.getAgentIdFromUrl(url));
    }

    public getOptions() {
        return this.options;
    }

}
