import { DataSource } from "@angular/cdk/collections";
import { Observable } from "rxjs";
import { CollectionViewer } from "@angular/cdk/collections";

export class SimpleDataSource<T> extends DataSource<T> {
    constructor(private data: Observable<T[]>) {
        super();
    }
    connect(collectionViewer: CollectionViewer): Observable<T[]> {
        return this.data;
    }
    disconnect(collectionViewer: CollectionViewer): void {

    }

}
