import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BigBreadcrumbsComponent} from "./big-breadcrumbs.component";
import {MinifyMenuComponent} from "./minify-menu.component";
import {NavigationComponent} from "./navigation.component";
import {UserModule} from "../../user/user.module";
import {RouterModule} from "@angular/router";
import {AgentsNavigationModule} from "../../../agents-navigation/agents-navigation.module";
import {NavigationOptionsModule} from "../navigation-options/navigation-options.module";
import {SpinnerModule} from "app/shared/spinner/spinner.module";
import {SmartMenuDirective} from "./smart-menu.directive";
import { MatNativeDateModule, MatCheckboxModule, MatProgressSpinnerModule } from "@angular/material";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchAgentComponent } from "./search-agent/search-agent.component";
import { MaterialModule} from "app/material.module";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        UserModule,
        AgentsNavigationModule,
        NavigationOptionsModule,
        SpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule,
    ],
    declarations: [
        BigBreadcrumbsComponent,
        MinifyMenuComponent,
        NavigationComponent,
        SmartMenuDirective,
        SearchAgentComponent
    ],
    exports: [
        BigBreadcrumbsComponent,
        MinifyMenuComponent,
        NavigationComponent
    ]
})
export class NavigationModule {

}

