import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef } from "@angular/core";
import { DOCUMENT } from "@angular/platform-browser";
import { Router, NavigationEnd } from "@angular/router";

import { Subscription } from "rxjs";

import { BroadcasterService } from "app/shared/utils/broadcaster.service";
import { ConstantsService } from "app/shared/utils/constants.service";
import { AccountManager } from "app/services/permissions/account-manager";
import { NavigationService } from "app/services/navigation.service";
import { MenuOption } from "app/shared/layout/navigation-options/menu-option";
import { TokenManager, AccountInfo } from "app/shared/utils/token-manager";
import { NavigationOptionsService } from "app/services/navigation-options.service";
import { LocalStorageService } from "app/shared/utils/local-storage.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-navigation-options",
    templateUrl: "./navigation-options.component.html",
    styleUrls: ["navigation-options.component.css"]
})
export class NavigationOptionsComponent implements OnInit, OnDestroy {

    public navigationOptionsView;
    public currentPath: string;
    private subscription: Subscription = new Subscription();
    private currentAgentId: string;
    public menuHidden: boolean;
    private isRootAgent: boolean;
    private isAgentCreditSource: boolean;

    private get navigationOptions() {
        return this.navigationOptionsService.getOptions();
    }

    private get account(): AccountInfo {
        return this.tokenManager.getAccountInfo();
    }

    constructor(private broadcasterService: BroadcasterService,
        private constantsService: ConstantsService,
        @Inject(DOCUMENT) private document: any,
        public accountManager: AccountManager,
        public tokenManager: TokenManager,
        private cd: ChangeDetectorRef,
        private router: Router,
        private navigationService: NavigationService,
        private navigationOptionsService: NavigationOptionsService,
        private localStorageService: LocalStorageService,
        private translate: TranslateService,
    ) {

    }

    ngOnInit() {
        this.registerRoutingEvent();
        this.registerAgentTreeLoaded();
        this.menuHidden = this.localStorageService.getItem("submenuOpen") === "true" ? true : false;
        if (this.menuHidden) { this.document.body.classList.add("hidden-subMenu"); }
        this.isRootAgent = this.currentAgentId === this.account.agentId;
        this.isAgentCreditSource = false;
        this.currentAgentId = this.navigationService.getAgentIdFromUrl(this.router.url);
        this.refreshMenuItems();
        this.currentPath = this.navigationOptionsService.findActivePath(this.router.url);
    }

    refreshMenuItems() {
        this.navigationOptionsView = this.navigationOptions.filter(o => o == null || o.visible(this.currentAgentId));
        this.cd.markForCheck();
    }

    private registerRoutingEvent() {
        this.subscription.add(
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.currentAgentId = this.navigationService.getAgentIdFromUrl(this.router.url);
                    this.isRootAgent = this.currentAgentId === this.account.agentId;
                    this.accessAdjustCredit(this.currentAgentId);
                    this.currentPath = this.navigationOptionsService.findActivePath(event.url);
                    this.refreshMenuItems();
                }
            })
        );
    }

    public toggleMenu() {
        this.menuHidden = !this.menuHidden;
        this.localStorageService.setItem("submenuOpen", this.menuHidden.toString());
        if (this.menuHidden) {
            this.document.body.classList.add("hidden-subMenu");
        } else {
            this.document.body.classList.remove("hidden-subMenu");
        }
    }

    private registerAgentTreeLoaded() {
        this.subscription.add(
            this.broadcasterService.on<string>(this.constantsService.AGENT_TREE_LOAD_EVENT)
                .subscribe(agentId => {
                    this.accessAdjustCredit(agentId);
                })
        );
    }

    private accessAdjustCredit(agentId: string): void {
        const localAgent = this.navigationService.getLocalAgent(agentId);
        if (localAgent) {
            this.isAgentCreditSource = localAgent.creditSource;
        }
    }

    public getRouterLink(path: string): string[] {
        return ["/", this.currentAgentId].concat(path.split("/"));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}

