import {
    Component, 
    Input,
    OnInit,
    OnDestroy,
    ChangeDetectorRef
} from "@angular/core";
import { Config } from "app/config/config.service";
import { FormatHelper } from "app/shared/utils/format-helper";
import { RowIterator } from "app/data/row-iterator";
import { TranslateService } from "@ngx-translate/core";
import { ReportService } from "app/services/report.service";
import { GameFamily36Detail  } from "app/data/entities/gameFamily36-detail";
import { gameFamily36Bets  } from "app/data/entities/gameFamily36-bets";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { PlayerGameService } from "../player-game.service";

@Component({
    selector: "app-gamefamily36-details",
    templateUrl: "gamefamily36-details.component.html",
    styleUrls: ["gamefamily36-details.component.css"]
})
export class GameFamily36DetailsComponent implements OnInit, OnDestroy {
    
    @Input() set rowData(value: RowIterator) {
        if (value) {
            this._rowData = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    @Input() set tokenhash(value: string) {
        if (value) {
            this._token = value;
        }
    }

    @Input() set datahash(value: string) {
        if (value) {
            this._encodedId = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    public playerBets: GameFamily36Detail[];
    public bShowMessageError: boolean = false;
    public errorCode: string="";
    public helpFileUrl: string;
    public isGameDetailReady: boolean = false;
    public currencysymbol: string;
    public Bets: Array<gameFamily36Bets>;
    private _rowData: RowIterator;
    private _token: string;
    private _encodedId: string;

    private currency: string;
    public isGlobalGameDetail: boolean = false;
    public globalGameDetailUrl: SafeResourceUrl;

    private playerGameServiceSubscription: Subscription;
    
    readonly IN_CASINO_GAMES_HISTORY_REPORT: number = 0;
    readonly GAMES_HISTORY_REPORT_GLOBAL_SERVICE: number = 1;

    public currencyClassification: string;

    constructor(
        private reportService: ReportService, 
        private config: Config,
        private cd: ChangeDetectorRef,
        private translate: TranslateService,
        private sanitizer: DomSanitizer,
        private playerGameService: PlayerGameService
    ) {
        this.isGlobalGameDetail = false;
        const url = "";
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        this.playerGameServiceSubscription = this.playerGameService.refresh$.subscribe(() => {
            if (this._rowData.currentRow.externalGameId === Number(this.config.gameDetailsReport.gameFamily36)){
                this.isGlobalGameDetail = false;
                this.getGameData();
            }
        });
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.playerGameServiceSubscription.unsubscribe();
    }

    get row(): any {
        if (this._rowData) {
            return this._rowData.currentRow;
        }
        return;
    }
    
    getGameData() {
        this.isGameDetailReady = false;
        const gameFamily36Detail = (this._rowData) ?
        this.reportService.getGameFamily36Detail(this.row.idStr) :
        this.reportService.getGameFamily36DetailEncoded(this._encodedId, this._token);
        
        gameFamily36Detail.subscribe((gameFamily36Detail: GameFamily36Detail) => {
            if (this.translate.currentLang === undefined){
                this.translate.currentLang = gameFamily36Detail.languagecode ? gameFamily36Detail.languagecode : "en";
                this.translate.setDefaultLang(this.translate.currentLang);
            }
            this.playerBets = [gameFamily36Detail];
            this.currency = gameFamily36Detail.currency;
            this.currencysymbol = gameFamily36Detail.currencySymbol;
            this.Bets = gameFamily36Detail.bets;
            this.getReportUrl(gameFamily36Detail);
            this.currencyClassification = gameFamily36Detail.currencyClassification;
            this.isGameDetailReady = true;
            this.cd.detectChanges();
        },
        err => {
            this.isGameDetailReady = false;
            this.translate.currentLang = "en";
            this.translate.setDefaultLang(this.translate.currentLang);
            this.bShowMessageError = true;
            this.errorCode =  err.status.toString();
            this.cd.detectChanges();
            return;
        });
        
        this.cd.markForCheck();
    }

    public getReportUrl(detail: GameFamily36Detail): void {
        this.isGlobalGameDetail = (detail.gamesHistoryReportProviderId === this.GAMES_HISTORY_REPORT_GLOBAL_SERVICE ? true : 
            (detail.gamesHistoryReportProviderId === this.IN_CASINO_GAMES_HISTORY_REPORT ? false : false));
        const url = detail.globalGameDetailsUrl;
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public getPayout(amount: number): string {
        return FormatHelper.currency(amount, this.currency, this.translate.currentLang, this.currencyClassification);
    }

    public getMessageError(error:string): string{
        this.bShowMessageError = true;
        if (error)
        {
            switch (error){
                case "400":
                    return "app.shared.labels.tokenExpired";
                case "404":
                    return 'app.casinos.generic-list.error-no-data';
                default:
                    return "app.auth.login.error-try-again-later";
            }
        }
    }
}
