
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient, HttpParams } from "@angular/common/http";


import { Observable } from "rxjs";

import { Config } from "../config/config.service";

import { TokenManager } from "../shared/utils/token-manager";
import { IUser } from "../data/entities/user";
import { Claim } from "app/data/entities/claim";
import { HttpHeaders } from "@angular/common/http";


@Injectable()
export class UserService {


    private url = `${this.config.apiUrl}/api/User`;
    private claimAgentId: String;

    constructor(private http: HttpClient,
        private config: Config,
        private tokenManager: TokenManager) {
    }
    public getUsers(agentId: string): Observable<IUser[]> {
        return this.http.get<IUser[]>(this.url, { params: new HttpParams().set("agentId", agentId) });
    }

    public updateUser(user: IUser, password: string): Observable<any> {
        return this.http.put(`${this.url}?id=${user.id}`, { user: user, password: password }, { responseType: "text" });
    }
    public createUser(user: IUser, password: string): Observable<any> {
        return this.http.post(this.url, { user: user, password: password }, { responseType: "text" }).pipe(
            map(r => r.substring(1, r.length - 1)));
    }
    public deleteUser(id: string): Observable<any> {
        return this.http.delete(`${this.url}?id=${id}`);
    }
    public getUserClaims(id: string): Observable<any> {
        return this.http.get(`${this.url}/getclaims?id=${id}`);

    }

    public setUserClaims(id: string, claims: Array<string>): Observable<any> {
        return this.http.post(`${this.url}/setclaims?id=${id}`, claims);
    }

    public getUser(id: string): Observable<IUser> {
        return this.http.get<IUser>(`${this.url}/getuser?id=${id}`);
    }

    public isNameAvailable(id: string, name: string): Observable<any> {
        return this.http.get<any>(`${this.url}/checkname?id=${id || ''}&name=${encodeURIComponent(name)}`);
    }

}
