import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Config } from "../config/config.service";


@Injectable()
export class WalletService {
    private url = `${this.config.casinoManagementServiceUrl}/api/wallet/agent/parent`;

    constructor(private http: HttpClient,
        private config: Config) {
    }

    public postWalletAgentWithParent(agentId: string, walletcode: string): Promise<any> {
        return this.http.post(`${this.url}?agentId=${agentId}&parentAgentId=${walletcode}`, null).toPromise();
    }

    public getWalletAgent(agentId: string): Observable<any> {
        return this.http.get<any>(`${this.url}?agentId=${agentId}`);
    }
}
