import { Component, Input } from "@angular/core";

@Component({
    selector: "app-card",
    templateUrl: "playing-card.component.html",
    styleUrls: ["playing-card.component.css"]
})
export class PlayingCardComponent {
    @Input() stacked: boolean;
    @Input() width: number;
    @Input() height: number;
    @Input() card: string;
    suits = ["C", "D", "H", "S"];
    static ranks = ["A", "2", "3", "4", "5", "6", "7", "8", "9", "T", "J", "Q", "K", "0"];

    get displayWidth(): number {
        return (this.stacked) ? this.width / 4 : this.width;
    }

    get offsetX(): number {
        const rank = this.card.substr(0, this.card.length - 1);
        const index = PlayingCardComponent.ranks.findIndex(s => s === rank);
        return this.scaledX - index * this.width;
    }
    get scaledX(): number {
        return 14 * this.width;
    }

    get scaledY(): number {
        return 4 * this.height;
    }

    get offsetY(): number {
        const suit = this.card.substr(this.card.length - 1);
        const index = this.suits.findIndex(s => s === suit);
        return index * this.height;
    }
}
