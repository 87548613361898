import { Injectable, OnDestroy } from "@angular/core";
import { Config } from "app/config/config.service";

import { ITimezone } from "app/data/entities/timezone";
import { ConstantsService } from "app/shared/utils/constants.service";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "app/shared/utils/local-storage.service";
import { CommonService } from "./common.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Observable, Observer, Subscription } from "rxjs";

@Injectable()
export class TimezonesService extends CommonService<ITimezone> implements OnDestroy {
    private cachedTranslatedTimezones: ITimezone[] = [];
    private subscriptions: Subscription = new Subscription();

    constructor(
        httpClient: HttpClient,
        localStorageService: LocalStorageService,
        config: Config,
        constantsService: ConstantsService,
        private translate: TranslateService
    ) {
        super(
            httpClient,
            localStorageService,
            `${config.apiUrl}/api/timezone`,
            constantsService.LS_TIMEZONES_KEY
        );

        this.subscriptions.add(
            this.translate.onLangChange.subscribe(async (_: LangChangeEvent) => {
                this.cachedTranslatedTimezones = await this.translateTimezones(this.cachedTranslatedTimezones);
            })
        );
    }

    async translateTimezones(timezones: ITimezone[]): Promise<ITimezone[]> {
        for (let timezone of timezones) {
            try {
                timezone.timezoneLocation = await this.translate.get(`app.shared.timezones.${timezone.id.toString().toLowerCase()}`).toPromise();
            } catch (err) {
                console.log(err);
            }
        }

        return timezones;
    }

    public getList(): Observable<ITimezone[]> {
        return new Observable((observer: Observer<ITimezone[]>) => {
            this.subscriptions.add(
                super.getList().subscribe(async (timezones: ITimezone[]) => {
                    this.cachedTranslatedTimezones = await this.translateTimezones(timezones);

                    observer.next(this.cachedTranslatedTimezones);
                    observer.complete();
                })
            );
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
