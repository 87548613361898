import { Component, Input } from "@angular/core";

@Component({
    selector: "app-card-stack",
    templateUrl: "playing-card-stack.component.html",
    styleUrls: ["playing-card-stack.component.css"]
})
export class PlayingCardStackComponent {
    @Input() cards: string[];
    @Input() height: number;
}
