import { Injectable } from "@angular/core";

@Injectable()
export class LocalStorageService {
    constructor() {
    }

    public setItem(key: string, value: string) {
        if (window.localStorage) {
            localStorage.setItem(key, value);
        } else {
            // Todo: Implement fallback.
        }
    }

    public removeItem(key: string): void {
        if (window.localStorage) {
            localStorage.removeItem(key);
        }
    }

    public getItem(key: string): string {
        if (window.localStorage) {
            return localStorage.getItem(key);
        } else {
            // Todo: Implement fallback.
            return "";
        }
    }
}
