import {Injectable} from "@angular/core";

@Injectable()
export class RequestTrackingService {
    private pendingRequests: number = 0;

    public registerRequest() {
        this.pendingRequests++;
    }

    public unregisterRequest() {
        if (this.pendingRequests > 0) {
            this.pendingRequests--;
        }
    }

    public isLoading(): boolean {
        return this.pendingRequests > 0;
    }
}