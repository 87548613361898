import { Injectable, OnDestroy } from "@angular/core";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Injectable()
export class ConstantsService implements OnDestroy {
    public readonly LS_COUNTRIES_KEY: string;
    public readonly LS_CURRENCIES_KEY: string;
    public readonly LS_TIMEZONES_KEY: string;
    public readonly LS_POS_ROLES_KEY: string;
    public readonly LS_REPORT_SETTINGS: string;
    public readonly LS_APP_LANGUAGE_SETTINGS_KEY: string;
    public readonly LS_EXCHANGERATES_KEY: string;
    public readonly AUTH_CLAIM: string;
    public readonly TOKEN_USER_NAME: string;
    public readonly TOKEN_USER_ID: string;
    public readonly APP_TOKEN: string;
    public readonly REGEXT_EMAIL: RegExp;
    public readonly REGEXT_GUID: RegExp;
    public readonly REGEXT_POS_USER_PASSWORD: RegExp;
    public readonly NUMBER: RegExp;
    public readonly CURRENCY: RegExp;
    public readonly AGENT_CHANGE_EVENT: string;
    public readonly AGENT_ADDED_EVENT: string;
    public readonly AGENT_TREE_LOAD_EVENT: string;
    public readonly AGENT_UPDATED_EVENT: string;
    public readonly ADJUST_CREDIT_EVENT: string;
    public GENERIC_ERROR_MESSAGE: string;
    public readonly ROUTE_AGENT_ID_PARAM_LEVEL: number;
    public readonly LS_LANGUAGE_KEY: string;
    public readonly CREDIT_AMOUNT_FORMAT: string;
    public readonly GENERIC_ERROR_TIMING: number;
    public readonly GENERIC_MESSAGE_TIMING: number;
    public readonly ONLY_POSITIVE_NUMBER: RegExp;
    public readonly POSITIVE_NEGATIVE_NUMBER: RegExp;
    public readonly REGEXT_IP: RegExp;
    public readonly REGEXT_WALLET_ID: RegExp;
    private subscriptions: Subscription = new Subscription();
    public static readonly COMBOBOX_SELECT_ALL: number = -999;
    public static readonly COMBOBOX_SELECT_NULL_VALUE: string = "__NULL__NULL__";
    public readonly PORTBALANCEPARENTUPDATEDMESSAGE: string = "Balance was updated as parent of balance";

    public roleApiNameToLabel: Map<string, string> = new Map<string, string>([
        ["Administrator", "app.poses.routing.pos-users.role.admin"],
        ["Cashier", "app.poses.routing.pos-users.role.cashier"],
        ["Manager", "app.poses.routing.pos-users.role.manager"],
        ["Operator", "app.poses.routing.pos-users.role.operator"],
        ["Supervisor", "app.poses.routing.pos-users.role.supervisor"],
    ]);
    public localizedRoleApiNames: Map<string, string> = new Map<string, string>([
        ["app.poses.routing.pos-users.role.admin", "Administrator"],
        ["app.poses.routing.pos-users.role.cashier", "Cashier"],
        ["app.poses.routing.pos-users.role.manager", "Manager"],
        ["app.poses.routing.pos-users.role.operator", "Operator"],
        ["app.poses.routing.pos-users.role.supervisor", "Supervisor"],
    ]);
    public transactionTypeApiNameToLabel: Map<string, string> = new Map<string, string>([
        ["DEPOSIT", "app.terminals.routing.terminals-transactions-report.transaction-type.deposit"],
        ["WITHDRAWAL", "app.terminals.routing.terminals-transactions-report.transaction-type.withdrawal"],
        ["Deposit", "app.terminals.routing.terminals-transactions-report.transaction-type.deposit"],
        ["Withdrawal", "app.terminals.routing.terminals-transactions-report.transaction-type.withdrawal"]
    ]);
    public localizedTransactionTypeApiNames: Map<string, string> = new Map<string, string>([
        ["app.terminals.routing.terminals-transactions-report.transaction-type.deposit", "DEPOSIT"],
        ["app.terminals.routing.terminals-transactions-report.transaction-type.withdrawal", "WITHDRAWAL"],
    ]);
    public multicashDescriptionToLabel: Map<string, string> = new Map<string, string>([
        ["Multicash Balance Created.", "app.reports.routing.reports-multicash-balance-report.description.multicash-balance-created"],
        ["Multicash Balance Updated.", "app.reports.routing.reports-multicash-balance-report.description.multicash-balance-updated"],
        ["Multicash Balance Deleted.", "app.reports.routing.reports-multicash-balance-report.description.multicash-balance-deleted"],
        ["Casino games syncronization", "app.reports.routing.reports-multicash-balance-report.description.casino-games-sync1"],
        ["Casino games synchronization", "app.reports.routing.reports-multicash-balance-report.description.casino-games-sync2"],
        ["Balance was updated as parent of balance", "app.reports.routing.reports-multicash-balance-report.description.multicash-parent-balance-updated"],
    ]);
    public localizedMulticashDescriptions: Map<string, string> = new Map<string, string>([
        ["app.reports.routing.reports-multicash-balance-report.description.multicash-balance-created", "Multicash Balance Created."],
        ["app.reports.routing.reports-multicash-balance-report.description.multicash-balance-updated", "Multicash Balance Updated."],
        ["app.reports.routing.reports-multicash-balance-report.description.multicash-balance-deleted", "Multicash Balance Deleted."],
        ["app.reports.routing.reports-multicash-balance-report.description.casino-games-sync1", "Casino games syncronization"],
        ["app.reports.routing.reports-multicash-balance-report.description.casino-games-sync2", "Casino games synchronization"],
        ["app.reports.routing.reports-multicash-balance-report.description.multicash-parent-balance-updated","Balance was updated as parent of balance"],
    ]);
    public localizedSuccessFailValues: Map<string, string> = new Map<string, string>([
        ["app.shared.labels.success", "Success"],
        ["app.shared.labels.fail", "Fail"],
    ]);
    public optinTypesToLabels: Map<string, string> = new Map<string, string>([
        ["OptOut", "app.reports.routing.optin.column-value-action-optout"],
        ["OptIn", "app.reports.routing.optin.column-value-action-optin"],
        ["ManualOptOut", "app.reports.routing.optin.column-value-action-manual-opt-out"],
        ["AutomaticOptOut", "app.reports.routing.optin.column-value-action-automatic-opt-out"],
    ]); 
    public localizedOptinTypes: Map<string, string> = new Map<string, string>([
        ["app.reports.routing.optin.column-value-action-optout", "Opt Out"],
        ["app.reports.routing.optin.column-value-action-optin", "Opt In"],
        ["app.reports.routing.optin.column-value-action-manual-opt-out", "Manual Opt Out"],
        ["app.reports.routing.optin.column-value-action-automatic-opt-out", "Automatic Opt Out"],
    ]);
    public featureTypesToLabels: Map<string, string> = new Map<string, string>([
        ["LuckyWheelOfAnni", "app.reports.routing.sidebet.feature-type.lucky-wheel"],
        ["FunnyFatty", "app.reports.routing.sidebet.feature-type.funny-fatty"],
        ["SideBetJackpot", "app.reports.routing.sidebet.feature-type.sidebet-jackpot"],
    ]);
    public localizedFeatureTypes: Map<string, string> = new Map<string, string>([
        ["app.reports.routing.sidebet.feature-type.lucky-wheel", "Lucky Wheel"],
        ["app.reports.routing.sidebet.feature-type.funny-fatty", "Funny Fatty"],
        ["app.reports.routing.sidebet.feature-type.sidebet-jackpot", "Side Bet Jackpot"],
    ]);

    public jackpotTypesToLabels: Map<string, string> = new Map<string, string>([
        ["Major", "app.reports.routing.jackpot-history.jackpot-type.major"],
        ["Minor", "app.reports.routing.jackpot-history.jackpot-type.minor"],
        ["Maxi", "app.reports.routing.jackpot-history.jackpot-type.maxi"],
        ["Mini", "app.reports.routing.jackpot-history.jackpot-type.mini"],
        ["Grand", "app.reports.routing.jackpot-history.jackpot-type.grand"],
        ["Random", "app.reports.routing.jackpot-history.jackpot-type.random"],
        ["SSD", "app.reports.routing.jackpot-history.jackpot-type.ssd"],
    ]); 

    public localizedJackpotTypes: Map<string, string> = new Map<string, string>([
        ["app.reports.routing.jackpot-history.jackpot-type.major", "Major"],
        ["app.reports.routing.jackpot-history.jackpot-type.minor", "Minor"],
        ["app.reports.routing.jackpot-history.jackpot-type.maxi", "Maxi"],
        ["app.reports.routing.jackpot-history.jackpot-type.mini", "Mini"],
        ["app.reports.routing.jackpot-history.jackpot-type.grand", "Grand"],
        ["app.reports.routing.jackpot-history.jackpot-type.random", "Random"],
        ["app.reports.routing.jackpot-history.jackpot-type.ssd", "SSD"],
    ]); 

    public localizedSymbolWonTypes: Map<string, string> = new Map<string, string>([
        ["app.shared.labels.yes", "Yes"],
        ["app.shared.labels.no", "No"],
        ["app.shared.labels.na", "N/A"]
    ]);
    public gameTechnologyTypesToLabels: Map<number, string> = new Map<number, string>([
        [0, "app.shared.label.selectall"],
        [1, "app.shared.technology.cpp"],
        [2, "app.shared.technology.flash"],
        [3, "app.shared.technology.html5"]
    ]);
    public localizedGameTechnologyTypes: Map<string, string> = new Map<string, string>([
        ["app.shared.label.selectall", "Select All"],
        ["app.shared.technology.cpp", "C++"],
        ["app.shared.technology.flash", "Flash"],
        ["app.shared.technology.html5", "HTML5"]
    ]);
    public locallizedChannelTypes: Map<string, string> = new Map<string, string>([
        ["app.shared.client.instantplay", "Instant Play"],
        ["app.shared.client.mobile", "Mobile"],
        ["app.shared.client.download", "Download"],
        ["app.shared.client.androidapp", "Android App"],
        ["app.shared.client.iosapp", "IOS App"]
    ]);
    public locallizedGameFamilyTypes: Map<string, string> = new Map<string, string>([
        ["app.shared.labels.table-game", "Table Game"],
        ["app.shared.labels.slot-game", "Slot"],
        ["app.shared.labels.specialty-game", "Specialty Game"],
        ["app.shared.labels.video-poker", "Video Poker"]
    ]);
    public GameFamilyTypesToLabels: Map<number, string> = new Map<number, string>([
        [0,"app.shared.labels.table-game"],
        [1,"app.shared.labels.table-game"],
        [2,"app.shared.labels.table-game"],
        [3,"app.shared.labels.table-game"],
        [5,"app.shared.labels.table-game"],
        [8,"app.shared.labels.table-game"],
        [9,"app.shared.labels.table-game"],
        [12,"app.shared.labels.table-game"],
        [19,"app.shared.labels.table-game"],
        [21,"app.shared.labels.table-game"],
        [24,"app.shared.labels.table-game"],
        [26,"app.shared.labels.table-game"],
        [30,"app.shared.labels.table-game"],
        [31,"app.shared.labels.table-game"],
        
        [6, "app.shared.labels.slot-game"],
        [18, "app.shared.labels.slot-game"],
        [27, "app.shared.labels.slot-game"],

        [4, "app.shared.labels.specialty-game"],
        [10, "app.shared.labels.specialty-game"],
        [13, "app.shared.labels.specialty-game"],
        [14, "app.shared.labels.specialty-game"],
        [15, "app.shared.labels.specialty-game"],
        [20, "app.shared.labels.specialty-game"],
        [23, "app.shared.labels.specialty-game"],
        [25, "app.shared.labels.specialty-game"],
        [33, "app.shared.labels.specialty-game"],
        [34, "app.shared.labels.specialty-game"],

        [7,"app.shared.labels.video-poker"],
        [16,"app.shared.labels.video-poker"],
        [17,"app.shared.labels.video-poker"],
        [22,"app.shared.labels.video-poker"]
    ]);

    public creditSourceLabel: string = "app.generic-list.credit-source";
    public creditSource: string;

    constructor(private translate: TranslateService) {
        this.AUTH_CLAIM = "AgentClaims";
        this.APP_TOKEN = "Token";
        this.ROUTE_AGENT_ID_PARAM_LEVEL = 1;

        // Events Names
        this.AGENT_CHANGE_EVENT = "AgentChangeEvent";
        this.AGENT_ADDED_EVENT = "AgentAdded";
        this.AGENT_UPDATED_EVENT = "AgentUpdatedEvent";
        this.AGENT_TREE_LOAD_EVENT = "AgentTreeEvent";
        this.ADJUST_CREDIT_EVENT = "AdjustCreditEvent";

        // LocalStorage Keys
        this.LS_COUNTRIES_KEY = "LSCountries";
        this.LS_TIMEZONES_KEY = "LSTimezones";
        this.LS_CURRENCIES_KEY = "LSCurrencies";
        this.LS_REPORT_SETTINGS = "LSReportSettings";
        this.LS_POS_ROLES_KEY = "LSPosRoles";
        this.LS_LANGUAGE_KEY = "LSLanguages";
        this.LS_APP_LANGUAGE_SETTINGS_KEY = "LSAppLanguageSettings";
        this.LS_EXCHANGERATES_KEY = "LSExchangeRates";

        // RegEx
        this.REGEXT_GUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
        // tslint:disable-next-line:max-line-length
        this.REGEXT_EMAIL = /^$|^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        // tslint:disable-next-line:max-line-length
        this.REGEXT_IP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])(\/([0-9]|[1-2][0-9]|3[0-2]))$/;

        this.REGEXT_WALLET_ID = /^[a-zA-Z0-9-_.]+$/;
        this.NUMBER = /^\d+$/;
        this.POSITIVE_NEGATIVE_NUMBER = /^-?[0-9]\d*(\.\d+)?$/;
        this.ONLY_POSITIVE_NUMBER = /^[+]?\d+([.]\d+)?$/;
        this.CURRENCY = /^\d+(.?\d{1,2})?$/;
        this.REGEXT_POS_USER_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d][!@#$%^&*()"'+-[{}\]\w\s]{5,}$/;

        // Error Messages
        this._updateTranslations();
        this.subscriptions.add(
            this.translate.onLangChange.subscribe((_: LangChangeEvent) => {
                this._updateTranslations();
            })
        );
        this.GENERIC_ERROR_TIMING = 10000;

        // Messages
        this.GENERIC_MESSAGE_TIMING = 5000;

        // Token
        this.TOKEN_USER_NAME = "unique_name";
        this.TOKEN_USER_ID = "sub";
        this.CREDIT_AMOUNT_FORMAT = "1.2-2";
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private _updateTranslations() {
        this.translate.get('app.shared.labels.generic-error-message').subscribe((localizedMessage: string) => {
            this.GENERIC_ERROR_MESSAGE = localizedMessage;
        });

        for (let roleLabel of Array.from(this.localizedRoleApiNames.keys())) {
            this.translate.get(roleLabel).subscribe((localizedRoleName: string) => {
                this.localizedRoleApiNames.set(roleLabel, localizedRoleName);
            });
        }

        for (let transactionTypeLabel of Array.from(this.localizedTransactionTypeApiNames.keys())) {
            this.translate.get(transactionTypeLabel).subscribe((localizedTransactionType: string) => {
                this.localizedTransactionTypeApiNames.set(transactionTypeLabel, localizedTransactionType);
            });
        }

        for (let multicashDescriptionLabel of Array.from(this.localizedMulticashDescriptions.keys())) {
            this.translate.get(multicashDescriptionLabel).subscribe((localizedDescription: string) => {
                this.localizedMulticashDescriptions.set(multicashDescriptionLabel, localizedDescription);
            });
        }

        this.translate.get(this.creditSourceLabel).subscribe((localizedCreditSource: string) => {
            this.creditSource = localizedCreditSource;
        });

        for (let roleLabel of Array.from(this.localizedRoleApiNames.keys())) {
            this.translate.get(roleLabel).subscribe((localizedRoleName: string) => {
                this.localizedRoleApiNames.set(roleLabel, localizedRoleName);
            });
        }

        for (let transactionTypeLabel of Array.from(this.localizedTransactionTypeApiNames.keys())) {
            this.translate.get(transactionTypeLabel).subscribe((localizedTransactionType: string) => {
                this.localizedTransactionTypeApiNames.set(transactionTypeLabel, localizedTransactionType);
            });
        }

        for (let multicashDescriptionLabel of Array.from(this.localizedMulticashDescriptions.keys())) {
            this.translate.get(multicashDescriptionLabel).subscribe((localizedDescription: string) => {
                this.localizedMulticashDescriptions.set(multicashDescriptionLabel, localizedDescription);
            });
        }

        for (let valLabel of Array.from(this.localizedSuccessFailValues.keys())) {
            this.translate.get(valLabel).subscribe((localizedValue: string) => {
                this.localizedSuccessFailValues.set(valLabel, localizedValue);
            });
        }

        for (let valLabel of Array.from(this.localizedOptinTypes.keys())) {
            this.translate.get(valLabel).subscribe((localizedValue: string) => {
                this.localizedOptinTypes.set(valLabel, localizedValue);
            });
        }

        for (let valLabel of Array.from(this.localizedSymbolWonTypes.keys())) {
            this.translate.get(valLabel).subscribe((localizedValue: string) => {
                this.localizedSymbolWonTypes.set(valLabel, localizedValue);
            });
        }

        for (let valLabel of Array.from(this.localizedFeatureTypes.keys())) {
            this.translate.get(valLabel).subscribe((localizedValue: string) => {
                this.localizedFeatureTypes.set(valLabel, localizedValue);
            });
        }

        for (let valLabel of Array.from(this.localizedGameTechnologyTypes.keys())) {
            this.translate.get(valLabel).subscribe((localizedValue: string) => {
                this.localizedGameTechnologyTypes.set(valLabel, localizedValue);
            });
        }

        for (let valLabel of Array.from(this.locallizedChannelTypes.keys())) {
            this.translate.get(valLabel).subscribe((localizedValue: string) => {
                this.locallizedChannelTypes.set(valLabel, localizedValue);
            });
        }

        for (let valLabel of Array.from(this.localizedJackpotTypes.keys())) {
            this.translate.get(valLabel).subscribe((localizedValue: string) => {
                this.localizedJackpotTypes.set(valLabel, localizedValue);
            });
        }

        for (let valLabel of Array.from(this.locallizedGameFamilyTypes.keys())) {
            this.translate.get(valLabel).subscribe((localizedValue: string) => {
                this.locallizedGameFamilyTypes.set(valLabel, localizedValue);
            });
        }
    }
}
