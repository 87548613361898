import { Injectable } from "@angular/core";
import { HttpHeaders } from "@angular/common/http";
import { Config } from "app/config/config.service";
import { HttpClient, HttpParams } from "@angular/common/http";
import { SlotGameDetail } from "app/data/entities/slot-game-detail";
import { BlackjackDetail } from "app/data/entities/blackjack-detail";
import { RouletteDetail } from "app/data/entities/roulette-detail";
import { KenoDetail } from "app/data/entities/keno-detail";
import { VPokerDetail } from "app/data/entities/vpoker-detail";
import { TriCardPokerDetail } from "app/data/entities/tricardpoker-detail";
import { Observable } from "rxjs";
import { GameFamily35Detail } from "app/data/entities/gameFamily35-detail";
import { GameFamily36Detail } from "app/data/entities/gameFamily36-detail";

@Injectable()
export class ReportService {
  private reportsUrl = `${this.config.reportServiceUrl}/api/report/gamedetail`;
  private blackjackReportsUrl = `${this.config.reportServiceUrl}/api/report/blackjackdetail`;
  private rouletteReportsUrl = `${this.config.casinoManagementServiceUrl}/api/report/roulettedetail`;
  private kenoReportsUrl = `${this.config.reportServiceUrl}/api/report/kenodetail`;
  private vPokerReportsUrl = `${this.config.reportServiceUrl}/api/report/videopokerdetail`;
  private tricardpokerReportsUrl = `${this.config.casinoManagementServiceUrl}/api/report/tricardpokerdetail`;
  private gameFamily35ReportsUrl = `${this.config.reportServiceUrl}/api/report/cardgamesdetail`;
  private gameFamily36ReportsUrl = `${this.config.reportServiceUrl}/api/report/crashgamesdetail`;

  constructor(private httpClient: HttpClient,
    private config: Config) {
  }
  public getSlotGameDetail(id: number, language: string): Observable<SlotGameDetail> {
    return this.httpClient.get<SlotGameDetail>(this.reportsUrl, {
      params: new HttpParams().set("betId", id.toString()).set("language", language)
    });
  }

  public getSlotGameDetailEncrypted(encryptedLink: string, token: string): Observable<SlotGameDetail> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let encryptedLinkPoco = { "encryptedLink": encryptedLink, "token": token };

    return this.httpClient.post<SlotGameDetail>(this.reportsUrl, encryptedLinkPoco, httpOptions);
  }

  public getBlackjackDetail(id: number): Observable<BlackjackDetail> {

    return this.httpClient.get<BlackjackDetail>(this.blackjackReportsUrl, {
      params: new HttpParams().set("betId", id.toString())
    });
  }

  public getBlackjackDetailEncoded(encodedId: string, token: string): Observable<BlackjackDetail> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let encryptedLinkPoco = { "encryptedLink": encodedId, "token": token };

    return this.httpClient.post<BlackjackDetail>(this.reportsUrl, encryptedLinkPoco, httpOptions);
  }

  public getGameFamily35Detail(id: number): Observable<GameFamily35Detail> {

    return this.httpClient.get<GameFamily35Detail>(this.gameFamily35ReportsUrl, {
      params: new HttpParams().set("betId", id.toString())
    });
  }

  public getGameFamily35DetailEncoded(encodedId: string, token: string): Observable<GameFamily35Detail> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let encryptedLinkPoco = { "encryptedLink": encodedId, "token": token };

    return this.httpClient.post<GameFamily35Detail>(this.reportsUrl, encryptedLinkPoco, httpOptions);
  }

  public getGameFamily36Detail(id: number): Observable<GameFamily36Detail> {

    return this.httpClient.get<GameFamily36Detail>(this.gameFamily36ReportsUrl, {
      params: new HttpParams().set("betId", id.toString())
    });
  }

  public getGameFamily36DetailEncoded(encodedId: string, token: string): Observable<GameFamily36Detail> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let encryptedLinkPoco = { "encryptedLink": encodedId, "token": token };

    return this.httpClient.post<GameFamily36Detail>(this.reportsUrl, encryptedLinkPoco, httpOptions);
  }

  public getRouletteDetail(id: number): Observable<RouletteDetail> {
    return this.httpClient.get<RouletteDetail>(this.rouletteReportsUrl, {
      params: new HttpParams().set("betId", id.toString())
    });
  }

  public getRouletteDetailEncoded(encodedId: string, token: string): Observable<RouletteDetail> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let encryptedLinkPoco = { "encryptedLink": encodedId, "token": token };

    return this.httpClient.post<RouletteDetail>(this.reportsUrl, encryptedLinkPoco, httpOptions);
  }

  public getVPokerDetail(id: number): Observable<VPokerDetail> {
    return this.httpClient.get<VPokerDetail>(this.vPokerReportsUrl, {
      params: new HttpParams().set("betId", id.toString())
    });
  }

  public getVPokerDetaillEncoded(encodedId: string, token: string): Observable<VPokerDetail> {

    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };

    const encryptedLinkPoco = { "encryptedLink": encodedId, "token": token };

    return this.httpClient.post<VPokerDetail>(this.reportsUrl, encryptedLinkPoco, httpOptions);
  }

  public getTriCardPokerDetail(id: number): Observable<TriCardPokerDetail> {
    return this.httpClient.get<TriCardPokerDetail>(this.tricardpokerReportsUrl, {
      params: new HttpParams().set("betId", id.toString())
    });
  }

  public getTriCardPokerDetailEncoded(encodedId: string, token: string): Observable<TriCardPokerDetail> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let encryptedLinkPoco = { "encryptedLink": encodedId, "token": token };

    return this.httpClient.post<TriCardPokerDetail>(this.reportsUrl, encryptedLinkPoco, httpOptions);
  }

  public getKenoDetail(id: number): Observable<KenoDetail> {
    return this.httpClient.get<KenoDetail>(this.kenoReportsUrl, {
      params: new HttpParams().set("betId", id.toString())
    });
  }

  public getKenoDetailEncoded(encodedId: string, token: string): Observable<KenoDetail> {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let encryptedLinkPoco = { "encryptedLink": encodedId, "token": token };

    return this.httpClient.post<KenoDetail>(this.reportsUrl, encryptedLinkPoco, httpOptions);
  }
}
