import {Commands} from "./commands";
import {Command} from "./command";

export class RequestReportCommand extends Command {
    constructor() {
        super(Commands.RequestReport);
    }

    report: ReportParameters;
}

export class ReportParameters {
    type: string;
    startDate: Date;
    endDate: Date;
}