import {
    Component, Input,
    ChangeDetectorRef,
    OnInit,
    OnDestroy
} from "@angular/core";
import { ReportService } from "app/services/report.service";
import { Subscription, Subject } from "rxjs";
import { DataSource } from "@angular/cdk/collections";
import { SimpleDataSource } from "app/data/datasets/simple.datasource";
import { Config } from "app/config/config.service";
import { FormatHelper } from "app/shared/utils/format-helper";
import { VPokerDetail } from "app/data/entities/vpoker-detail";
import { PlayerGameService } from "app/generic-list/custom-actions/player-game-actions/player-game.service";
import { RowIterator } from "app/data/row-iterator";
import { TranslateService } from "@ngx-translate/core";
import { BonusRound } from "app/data/entities/vpoker-detail-bonus-round";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "app-vpoker-details",
    templateUrl: "vpoker-details.component.html",
    styleUrls: ["vpoker-details.component.css"]
})
export class VPokerDetailsComponent implements OnInit, OnDestroy {

    @Input() set rowData(value: RowIterator) {
        if (value) {
            this._rowData = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    @Input() set tokenhash(value: string) {
        if (value) {
            this._token = value;
        }
    }

    @Input() set datahash(value: string) {
        if (value) {
            this._encodedId = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    private _rowData: RowIterator;
    private _encodedId: string;
    private _token: string;
    currencyClassification: string;

    get row(): any {
        if (this._rowData) {
            return this._rowData.currentRow;
        }
        return;
    }

    detailsColumns = ["bet", "payout", "payoutType"];

    bonuColumns = ["doubleUpRound", "dealerCards", "playerCards", "outcome"];

    public vPokerDetail: Subject<VPokerDetail[]>;
    public bonusDetail: Array<BonusRound> = [];
    public bShowMessageError = false;
    public errorCode = "";
    public date: string;
    public time: string;
    public currency: string;
    public dealtCards: string;
    public heldCards: string;
    public handAfterDraw: string;
    private localizedYesNoOptions: Map<string, string> = new Map();
    private subscriptions: Subscription = new Subscription();
    private playerGameServiceSubscription: Subscription;
    public isLoading: boolean;

    public isGlobalGameDetail: boolean = false;
    public globalGameDetailUrl: SafeResourceUrl;

    readonly IN_CASINO_GAMES_HISTORY_REPORT: number = 0;
    readonly GAMES_HISTORY_REPORT_GLOBAL_SERVICE: number = 1;

    constructor(
        private reportService: ReportService,
        private config: Config,
        private playerGameService: PlayerGameService,
        private cd: ChangeDetectorRef,
        private translate: TranslateService,
        private sanitizer: DomSanitizer
    ) {
        this.isGlobalGameDetail = false;
        const url = "";
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

        this.playerGameServiceSubscription = this.playerGameService.refresh$.subscribe(() => {
            if (this._rowData.currentRow.externalGameId === Number(this.config.gameDetailsReport.vpoker)){
                this.isGlobalGameDetail = false;
                this.getGameData();
            }
        });
        this.vPokerDetail = new Subject();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.playerGameServiceSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    showPreviousItem() {
        this.playerGameService.back();
    }

    showNextItem() {
        this.playerGameService.forward();
    }

    get detailsDatasource(): DataSource<VPokerDetail> {
        return new SimpleDataSource(this.vPokerDetail.asObservable());
    }

    getGameData() {
        const vpokerDetail = (this._rowData) ?
        this.reportService.getVPokerDetail(this.row.idStr) :
        this.reportService.getVPokerDetaillEncoded(this._encodedId, this._token);

        this.isLoading = true;

        vpokerDetail.subscribe(item => {
            if (this.translate.currentLang === undefined) {
                this.translate.currentLang = item.languagecode ? item.languagecode : "en";
                this.translate.setDefaultLang(this.translate.currentLang);
            }
            this.date = FormatHelper.shortDate(item.gameDate, this.translate.currentLang);
            this.time = FormatHelper.shortTime(item.gameDate, this.translate.currentLang);
            this.currency = item.currency;
            this.vPokerDetail.next([item]);
            this.bonusDetail = this.getBonusDetails(item.bonusGameResults);
            this.dealtCards = item.dealtCards;
            this.heldCards = item.heldCards;
            this.handAfterDraw = item.handAfterDraw;
            this.currencyClassification = item.currencyClassification;
            this.getReportUrl(item);

            this.isLoading = false;
        },
        err => {
            this.translate.currentLang = "en";
            this.translate.setDefaultLang(this.translate.currentLang);
            this.bShowMessageError = true;
            this.errorCode =  err.status.toString();
            this.cd.detectChanges();
        });
        this.cd.markForCheck();
    }

    public getReportUrl(detail: VPokerDetail): void{
        this.isGlobalGameDetail = (detail.gamesHistoryReportProviderId === this.GAMES_HISTORY_REPORT_GLOBAL_SERVICE ? true : 
            (detail.gamesHistoryReportProviderId === this.IN_CASINO_GAMES_HISTORY_REPORT ? false : false));
        const url = detail.globalGameDetailsUrl;
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public removeJocker(cards: string, flag_bonus: false) {
        if (cards) {
            cards = cards.replace("Joker", "0C").replace("Joker", "0C"); // replace, work only once | Max 2 jokers
            if (flag_bonus) {
                return cards.trim().split(",").filter(card => card !== "");
            }

            return cards.split(" ").filter(card => card !== "");
        }
        return "";
    }

    public getMessageError(error: string): string {
        this.bShowMessageError = true;
        if (error) {
            switch (error){
                case "400":
                    return "app.shared.labels.tokenExpired";
                    break;
                case "404":
                    return "app.casinos.generic-list.error-no-data";
                    break;
                default:
                    return "app.auth.login.error-try-again-later";
            }
        }
    }

    public toDateTime(value: any): string {
        return FormatHelper.shortDateAndTime(value, this.translate.currentLang);
    }

    private getBonusDetails(data: Array<BonusRound>) {
        return data.length > 0 && data[0].doubleUpRound && data[0].doubleUpRound.length > 0 ? data : null;
    }

    public getImgUrl(url: string): string {
        return this.config.imageCdnUrl + url;
    }

    public getPayout(amount: number): string {
        return FormatHelper.currency(amount, this.currency, this.translate.currentLang, this.currencyClassification);
    }

    public getYesOrNo(bool: boolean): string {
        return this.localizedYesNoOptions.get(bool ? "app.shared.labels.yes" : "app.shared.labels.no");
    }

    public getNumberDescription(value: any): string {
        return this.translate.instant(value);
    }
}
