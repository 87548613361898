
import {empty as observableEmpty, of as observableOf, fromEvent as observableFromEvent,  Observable ,  Subscription } from 'rxjs';

import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Router } from "@angular/router";
import { Component, OnInit, ElementRef, ViewChild, Input,
        EventEmitter, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";

import { NavigationService } from "app/services/navigation.service";
import { NavigationOptionsService } from "app/services/navigation-options.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: "app-search-agent",
    templateUrl: "./search-agent.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./search-agent.component.css"]
})
export class SearchAgentComponent implements OnInit, OnDestroy {

    private searchSubscription: Subscription;
    public filteredAgents: Observable<any[]>;
    @Input() agentId: string;
    @ViewChild("SearchTextInput") filterTemplate: ElementRef;

    constructor(
        private navigationService: NavigationService,
        private router: Router,
        private cd: ChangeDetectorRef,
        private navigationOptionsService: NavigationOptionsService,
        private translate: TranslateService,
    ) { }

    ngOnInit() {
        this.searchSubscription = observableFromEvent(this.filterTemplate.nativeElement, "keyup").pipe(
            debounceTime(100),
            distinctUntilChanged(),)
            .subscribe((e: KeyboardEvent) => {
                if (e.keyCode !== 40 && e.keyCode !== 39 && e.keyCode !== 38 && e.keyCode !== 37 ) {
                    const value = e.target["value"];
                    if (value.length > 0) {
                        this.navigationService.searchAgents(this.agentId, value)
                            .subscribe(res => {
                                this.filteredAgents = observableOf(res);
                                this.cd.markForCheck();
                            });
                    } else {
                        this.filteredAgents = observableEmpty();
                        this.cd.markForCheck();
                    }
                }
            });
    }

    ngOnDestroy() {
        this.searchSubscription.unsubscribe();
    }

    public agentPicked(e, agentId: string) {
        if (e.isUserInput) {
            const landingRoute = this.navigationOptionsService.getLandingRoute(agentId);
            this.router.navigate([`/${agentId}/${landingRoute}`]);
        }
    }

}
