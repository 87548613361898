import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AppLanguageService } from "../../../../services/appLanguage.service";

@Component({
    selector: "app-lang-switcher",
    templateUrl: "./app-lang-switcher.component.html",
    styleUrls: ["./app-lang-switcher.component.css"]
  })
  export class AppLangSwitcherComponent {
    searchMobileActive = false;
  
    constructor(
      public translate: TranslateService,
      public appLangService: AppLanguageService,
    ) {
    }
  }
  