import {NgModule} from "@angular/core";
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {RouterModule} from '@angular/router';


import {
    AlertModule,
    BsDropdownModule,
    ButtonsModule,
    ModalModule,
    ProgressbarModule,
    TabsModule,
    TooltipModule
} from 'ngx-bootstrap'

import {SmartadminLayoutModule} from './layout'

import {SmartadminWidgetsModule} from "./widgets/smartadmin-widgets.module";

import {UtilsModule} from "./utils/utils.module";
import {SmartadminFormsLiteModule} from "./forms/smartadmin-forms-lite.module";
import { TranslateModule } from "@ngx-translate/core";


@NgModule({
    imports: [
        CommonModule, 
        FormsModule, 
        HttpModule, 
        RouterModule,
        TranslateModule,
    ],
    declarations: [],
    exports: [
        CommonModule, FormsModule, HttpModule, RouterModule,

        ModalModule,
        ButtonsModule,

        AlertModule,
        TabsModule,
        TooltipModule,
        BsDropdownModule,
        ProgressbarModule,

        SmartadminLayoutModule,

        UtilsModule,

        SmartadminFormsLiteModule,

        SmartadminWidgetsModule,

    ]
})
export class SmartadminModule {
}
