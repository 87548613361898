import {
    Component, Input, ChangeDetectorRef,
    OnInit,
    OnDestroy
} from "@angular/core";
import { ReportService } from "app/services/report.service";
import { Subscription ,  Subject } from "rxjs";
import { DataSource } from "@angular/cdk/collections";
import { SimpleDataSource } from "app/data/datasets/simple.datasource";
import { Config } from "app/config/config.service";
import { FormatHelper } from "app/shared/utils/format-helper";
import { KenoDetail } from "app/data/entities/keno-detail";
import { PlayerGameService } from "app/generic-list/custom-actions/player-game-actions/player-game.service";
import { RowIterator } from "app/data/row-iterator";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "app-keno-details",
    templateUrl: "keno-details.component.html",
    styleUrls: ["keno-details.component.css"]
})
export class KenoDetailsComponent implements OnInit, OnDestroy {

    @Input() set rowData(value: RowIterator) {
        if (value) {
            this._rowData = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    @Input() set tokenhash(value: string) {
        if (value) {
            this._token = value;
        }
    }

    @Input() set datahash(value: string) {
        if (value) {
            this._encodedId = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    private _rowData: RowIterator;
    private _encodedId: string;
    private _token: string;
    currencyClassification: string;

    get row(): any {
        if (this._rowData) {
            return this._rowData.currentRow;
        }    
    }

    table1Columns = ["totalBet", "totalPayout", "bonusBet", "payBonus"];

    table2Columns = ["results"];

    table3Columns = ["picks"];

    public kenoDetail: Subject<KenoDetail[]>;

    public bShowMessageError: boolean = false;
    public errorCode: string="";
    public date: string;
    public time: string;
    public results: string;
    private subscriptions: Subscription = new Subscription();
    private currency: string;
    private playerGameServiceSubscription: Subscription;
    public isLoading: boolean;

    public isGlobalGameDetail: boolean = false;
    public globalGameDetailUrl: SafeResourceUrl;

    readonly IN_CASINO_GAMES_HISTORY_REPORT: number = 0;
    readonly GAMES_HISTORY_REPORT_GLOBAL_SERVICE: number = 1;

    constructor(
        private reportService: ReportService, 
        private config: Config,
        private playerGameService: PlayerGameService, 
        private cd: ChangeDetectorRef,
        private translate: TranslateService,
        private sanitizer: DomSanitizer
    ) {
        this.isGlobalGameDetail = false;
        const url = "";
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

        this.playerGameServiceSubscription = this.playerGameService.refresh$.subscribe(() => {
            if (this._rowData.currentRow.externalGameId === Number(this.config.gameDetailsReport.keno)){
                this.isGlobalGameDetail = false;
                this.getGameData();
            }
        });
        this.kenoDetail = new Subject();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.playerGameServiceSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    get detailsDatasource(): DataSource<KenoDetail> {
        return new SimpleDataSource(this.kenoDetail.asObservable());
    }

    getGameData() {
        const kenoDetail = (this._rowData) ? 
            this.reportService.getKenoDetail(this.row.idStr) : 
            this.reportService.getKenoDetailEncoded(this._encodedId, this._token);
        
        this.isLoading = true;

        kenoDetail.subscribe(item => {

            if (this.translate.currentLang === undefined){
                this.translate.currentLang = item.languagecode ? item.languagecode : "en";
                this.translate.setDefaultLang(this.translate.currentLang);
            }  

            this.date = FormatHelper.shortDate(item.dateTime, this.translate.currentLang);
            this.time = FormatHelper.shortTime(item.dateTime, this.translate.currentLang);
            this.results = item.results;
            this.kenoDetail.next([item]);
            this.currency = item.currency;
            this.currencyClassification = item.currencyClassification;
            this.getReportUrl(item);

            this.isLoading = false;

        },
        err => {
            this.translate.currentLang = "en";
            this.translate.setDefaultLang(this.translate.currentLang);
            this.bShowMessageError = true;
            this.errorCode =  err.status.toString();
            this.cd.detectChanges();
        });
        this.cd.markForCheck();
    }

    public getReportUrl(detail: KenoDetail): void{
        this.isGlobalGameDetail = (detail.gamesHistoryReportProviderId === this.GAMES_HISTORY_REPORT_GLOBAL_SERVICE ? true : 
            (detail.gamesHistoryReportProviderId === this.IN_CASINO_GAMES_HISTORY_REPORT ? false : false));
        const url = detail.globalGameDetailsUrl;
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public getImgUrl(url: string): string {
        return this.config.imageCdnUrl + url;
    }

    public getPayout(amount: number): string {
        return FormatHelper.currency(amount, this.currency, this.translate.currentLang, this.currencyClassification);
    }

}
