import { Component, OnInit, Input } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-back-button",
    templateUrl: "./back-button.component.html",
    styleUrls: ["./back-button.component.css"]
})
export class BackButtonComponent implements OnInit {

    @Input() previousUrl: any;
    constructor(
        private router: Router,
        private translate: TranslateService,
    ) { }

    ngOnInit() {

    }

}
