
import {throwError as observableThrowError,  Observable } from "rxjs";

import {finalize, tap, catchError} from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { TokenManager } from "app/shared/utils/token-manager";
import { RequestTrackingService } from "app/shared/utils/request-tracking.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private tokenManager: TokenManager, private requestTrackingService: RequestTrackingService, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let request: HttpRequest<any> = req;

        const token = this.tokenManager.retrieveToken();
        if (token) {
            request = req.clone({ headers: req.headers.set("Authorization", token) });
        }

        this.requestTrackingService.registerRequest();

        return next.handle(request).pipe(catchError(this.handleError),
            tap((res: Response) => {}
            , (err: any) => {
                try {
                    console.log("Error: " + JSON.stringify(err));
                } catch (_) {
                    console.log("Error: " + err);
                }
            }),
            finalize(() => {
                this.requestTrackingService.unregisterRequest();
            }),);
    }


    public handleError = (error: Response) => {
        if (error.status == 401) {
            this.tokenManager.removeToken();
            this.router.navigate(["/"]);
        }
        return observableThrowError(error);

    }
}