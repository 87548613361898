import {Component, OnInit} from '@angular/core';
import {LayoutService} from "../../layout.service";
import { TranslateService } from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'sa-collapse-menu',
  templateUrl: './collapse-menu.component.html'
})
export class CollapseMenuComponent {

  constructor(
    private layoutService: LayoutService,
    private translate: TranslateService,
  ) {

  }

  onToggle() {
    this.layoutService.onCollapseMenu()
  }
}
