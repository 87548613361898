
import {map} from "rxjs/operators";
import {Response} from "@angular/http";
import {Observable} from "rxjs";
import {CreditTransfersListComponent} from "app/reports/credittransfers/credittransferlist.component";
import {GenericDto, ITabularDataService} from "app/data/datasets/generic.datasourceserverside";
import {Injectable} from "@angular/core";
import {TokenManager} from "../shared/utils/token-manager";
import {Config} from "../config/config.service";
import {TabularDataParams} from "app/data/entities/tabulardata";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import { TranslateService } from "@ngx-translate/core";

@Injectable()
export class GenericListService implements ITabularDataService<any> {

    private claimAgentId: String;
    constructor(private http: HttpClient,
                private config: Config,
                private tokenManager: TokenManager,
                private translate: TranslateService) {
    }

    getData(url: string, reportParams: TabularDataParams): Observable<GenericDto<any>> {
        const response = this.http.post(url, reportParams).pipe(
            map((result: Response) => this.readApiResult(result)));
        return response;
    }

    getDataToExportCSV(url: string, reportParams: TabularDataParams, filename: string) {
        const reportName =  filename.substring(filename.lastIndexOf("/") + 1);
        const response = this.http.post(url + "?lng=" +  this.translate.currentLang, reportParams,
            { headers: new HttpHeaders({ "accept" : "text/csv", "rname": reportName}),
                observe: "response", responseType: "text"})
            .pipe(
                map((result: Response) => this.saveAs(result)));
        return response;

    }

    private readApiResult(result: any): GenericDto<any> {
        return result as GenericDto<any>;
    }

    private saveAs(data: any) {
        return data.body;
    }
}

