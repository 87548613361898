import { Routes, RouterModule } from "@angular/router";
import { MainLayoutComponent } from "./shared/layout/app-layouts/main-layout.component";
import { AuthLayoutComponent } from "./shared/layout/app-layouts/auth-layout.component";
import { GameHistoryPublicComponent } from "./miscellaneous/game-history-public/game-history-public.component";
import { ModuleWithProviders } from "@angular/core";
import { AuthGuard } from "./auth-guard.service";
import { LoggedGuard } from "./logged-guard.service";

export const routes: Routes = [
    {
        path: "",
        redirectTo: "", // AuthGuard redirect to default page
        pathMatch: "full",
        canActivate: [AuthGuard]
    },
    {
        path: "gamedetail",
        component: GameHistoryPublicComponent,
        data: {pageTitle: "Game History"}
    },
    {
        path: ":agentId",
        component: MainLayoutComponent,
        data: {pageTitle: "app.routing.title-agency-system"},
        canActivate: [AuthGuard],
        children: [
            {
                path: "agents",
                loadChildren: "app/agents/agents.module#AgentsModule",
                data: {pageTitle: "app.shared.labels.agents"},
                canActivate: [AuthGuard]
            },
            {
                path: "users",
                loadChildren: "app/users/users.module#UsersModule",
                data: {pageTitle: "app.shared.labels.users"},
                canActivate: [AuthGuard]
            },
            {
                path: "players",
                loadChildren: "app/players/players.module#PlayersModule",
                data: {pageTitle: "app.shared.labels.players"},
                canActivate: [AuthGuard]
            },
            {
                path: "casinos",
                loadChildren: "app/casinos/casinos.module#CasinosModule",
                data: {pageTitle: "app.shared.labels.casinos"},
                canActivate: [AuthGuard]
            },
            {
                path: "roles",
                loadChildren: "app/roles/roles.module#RolesModule",
                data: { pageTitle: "app.shared.labels.roles" },
                canActivate: [AuthGuard]
            },
            {
                path: "reports",
                loadChildren: "app/reports/reports.module#ReportsModule",
                data: { pageTitle: "app.routing.agency-system.title-report" },
                canActivate: [AuthGuard]
            },
            {
                path: "poses",
                loadChildren: "app/poses/pos.module#PosModule",
                data: {pageTitle: "app.routing.agency-system.title-pos"},
                canActivate: [AuthGuard]
            },
            {
                path: "terminals",
                loadChildren: "app/terminals/terminal.module#TerminalModule",
                data: {pageTitle: "app.shared.labels.terminals"},
                canActivate: [AuthGuard]
            }
        ]
    },

    {
        path: "auth",
        component: AuthLayoutComponent,
        children: [
            {path: "", redirectTo: "login", pathMatch: "full"},
            {path: "login", loadChildren: "app/auth/login/login.module#LoginModule", canActivate: [LoggedGuard]}
        ]
    },
    {
        path: "error/500",
        component: AuthLayoutComponent,
        children: [
            {path: "", loadChildren: "app/miscellaneous/error500/error500.module#Error500Module"},
            // { path: '500', loadChildren: 'app/auth/login/login.module#LoginModule', canActivate: [LoggedGuard]}
            // { path: 'register', loadChildren: 'app/auth/register/register.module#RegisterModule'}
        ]
    },
    {
        path: "error/404",
        component: AuthLayoutComponent,
        children: [
            {path: "", loadChildren: "app/miscellaneous/error404/error404.module#Error404Module"}
        ]
    },
//  { path: 'auth', component: AuthLayoutComponent, loadChildren: 'app/auth/+login/login.module#LoginModule'},


  {path: "**", redirectTo: "error/404"}
//
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes);
