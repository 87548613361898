import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Config } from "app/config/config.service";

@Component({
  selector: 'app-game-history-public',
  templateUrl: './game-history-public.component.html',
  styleUrls: ['./game-history-public.component.css']
})
export class GameHistoryPublicComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private config: Config
  ) {}

  public gameIdHash : string;
  public tokenId : string;
  private externalGameId : string;

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.gameIdHash = params.id;
      this.tokenId=params.token;
      this.externalGameId = params.egi;
    });
  }

  public ifReport(report: string): boolean {
    return (this.checkFamily(this.config.gameDetailsReport.slot) && report === "slot"
        || this.checkFamily(this.config.gameDetailsReport.blackjack) && report === "blackjack"
        || this.checkFamily(this.config.gameDetailsReport.roulette) && report === "roulette"
        || this.checkFamily(this.config.gameDetailsReport.keno) && report === "keno"
        || this.checkFamily(this.config.gameDetailsReport.vpoker) && report === "vpoker"
        || this.checkFamily(this.config.gameDetailsReport.tricardpoker) && report === "tricardpoker");
  }

  checkFamily(source: string): boolean {
      return source.split(",").map(s => Number(s)).some(n => n === Number(this.externalGameId));
  }

}
