import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import { TokenManager } from "app/shared/utils/token-manager";
import { ConstantsService } from "app/shared/utils/constants.service";
import { NavigationService } from "app/services/navigation.service";
import { Claims, ClaimTypes, ClaimGroups } from "app/services/permissions/claims";

@Injectable()
export class AccountManager {
    // adding claim names from AgencySystem repo
    constructor(private tokenManager: TokenManager,
        private constantService: ConstantsService,
        private navigationService: NavigationService,
        private router: Router) {
    }

    isAuthorized(type: string, value: string, agentId: string, treePermission: string, restrictAgent = false): boolean {
        const canGoDownTheTree = this.hasClaim(ClaimTypes.RtgClaims, treePermission);
        const agent = this.navigationService.getLocalAgent(agentId);
        const account = this.tokenManager.getAccountInfo();
        return (this.hasClaim(type, value)) &&
            (account.agentId === agentId || agent && account.agentId === agent.parentId && !restrictAgent || canGoDownTheTree);
    }

    private hasClaim(type: string, value: string): boolean {
        const claim = this.tokenManager.getValueFromToken(type);
        return claim ? (value == null || claim.indexOf(value) !== -1) : false;
    }

    public canDeleteUser(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanDeleteUsers,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canCreditAgent(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanCreditAgent,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canRemoveCredits(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanRemoveAgentsCredits,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canAddAgent(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanAddAgent,
            agentId, Claims.CanEdit2ndPlusLevelChildren, true);
    }

    public canCreateUser(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanCreateUsers,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

   public canListUsers(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanListUsers,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewTerminals(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewTerminals,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canSeeUserClaims(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanSeeUserClaims,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canEditUserClaims(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanEditUserClaims,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canEditUsers(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanEditUsers,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canRequestUserDetails(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanRequestUserDetails,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canRequestCurrencyExchange(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanRequestCurrencyExchange,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewUserRoles(): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanViewUserRoles);
    }

    public canEditUserRoles(): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanEditUserRoles);
    }

    public canViewAnyReport(agentId: string): boolean {
        for (const claim of ClaimGroups.Reports) {
            if (this.isAuthorized(ClaimTypes.RtgClaims, claim, agentId, Claims.CanView2ndPlusLevelChildren)) {
                return true;
            }
        }
        return false;
    }

    public canViewReportsList(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, null,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewCreditTransferReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewCreditTransfer,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewPlayerGame(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewPlayerGame,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public CanViewCasinoPerformance(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewCasinoPerformance,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }
    
    public CanViewPlayerPerformance(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewPlayerPerformance,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewTerminalTransactionReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewTerminalTransaction,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewAgentSummaryReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewAgentSummary,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewCafeSummaryReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewCafeSummary,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewGamePerformanceReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewGamePerformance,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewGameActivityReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewGameActivity,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewPlayerSessionsReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewPlayerSessions,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewAgents(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewAgent,
            agentId, Claims.CanView2ndPlusLevelChildren, true);
    }

    public canEditAgents(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanEditAgent,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canViewPointOfSales(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewPointOfSales,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canCreatePointOfSales(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanCreatePointOfSales,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canEditPointOfSales(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanEditPointOfSales,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canDeletePointOfSales(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanDeletePointOfSales,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canCreditPointOfSales(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanCreditPointOfSale,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canRemovePointOfSalesCredits(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanRemovePointofSalesCredits,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canSetCreditSource(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanSetCreditSource,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canViewPointOfSalesUsers(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewPointOfSalesUsers,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canAddPointOfSalesUsers(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanAddPointOfSalesUsers,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canEditPointOfSalesUsers(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanEditPointOfSalesUsers,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }

    public canChangePasswordOfPointOfSalesCredits(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanChangePasswordOfPointOfSalesUsers,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }
    public canViewCasinos(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanViewCasinos);
    }

    public canCreateCasino(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanCreateCasino);
    }

    public canUpdateCasino(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanUpdateCasino);
    }

    public canDeleteCasino(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanDeleteCasino);
    }

    public canDeletePermanently(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanDeletePermanently);
    }

    public canViewCasinoAssigment(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewCasinoAssignment,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canEditCasinoAssigment(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanEditCasinoAssignment,
            agentId, Claims.CanEdit2ndPlusLevelChildren);
    }


    public canViewGameHistoryReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewGameHistory,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewBalanceAdjustmentsReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewBalanceAdjustments,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewSideBetsReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewSideBets,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canViewOptInReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewOptIn,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canListPlayers(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanListPlayers);
    }

    public canCreatePlayers(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanCreatePlayers);
    }

    public canEditPlayers(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanEditPlayers);
    }

    public canDeletePlayers(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanDeletePlayers);
    }

    public canLogoutPlayers(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanLogoutPlayers);
    }
    public canViewIpWhitelistRules(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanViewIpWhitelistRules);
    }

    public canEditIpWhitelistRules(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanEditIpWhitelistRules);
    }

    public canRequestExtraInfoOfAgents(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanRequestExtraInfoOfAgents);
    }

    public canViewWalletEndpoints(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanViewWalletEndpoints);
    }

    public canUpdateWalletEndpoints(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanUpdateWalletEndpoints);
    }

    public canCreateWalletEndpoints(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanCreateWalletEndpoints);
    }

    public canViewWalletAgent(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanViewWalletAgent);
    }

    public canUpdateWalletAgent(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanUpdateWalletAgent);
    }

    public canCreateWalletAgent(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanCreateWalletAgent);
    }

    public canRedeemBonus(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanRedeemBonus);
    }

    public canDenyBonus(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanDenyBonus);
    }

    public canViewBonusInformation(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanViewBonusInformation);
    }

    public canCdnAgentConfiguration(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanCdnAgentConfiguration);
    }

    public canListSidebetOperators(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanListSidebetOperators);
    }

    public canViewSidebetOperator(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanViewSidebetOperator);
    }

    public canCreateSidebetOperator(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanCreateSidebetOperator);
    }

    public canUpdateSidebetOperator(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanUpdateSidebetOperator);
    }

    public canDeleteSidebetOperator(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanDeleteSidebetOperator);
    }

    public canViewJackpotHistory(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanViewJackpotHistory);
    }

    public canViewPendingGamesReport(agentId: string): boolean {
        return this.isAuthorized(ClaimTypes.RtgClaims, Claims.CanViewPendingGames,
            agentId, Claims.CanView2ndPlusLevelChildren);
    }

    public canClosePendingGames(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanClosePendingGames);
    }

    public canCloseCasinoPendingGames(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanCloseCasinoPendingGames);
    }

    public canWipePointOfSales(agentId: string): boolean {
        return this.hasClaim(ClaimTypes.RtgClaims, Claims.CanWipePointOfSales);
    }
}
