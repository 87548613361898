import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";

import { Config } from "../config/config.service";
import { IVersion } from "../data/entities/version";
import { VersionTypes } from "app/data/enums/versionTypes";


@Injectable()
export class VersionService {
    constructor(
        private http: HttpClient,
        private config: Config) {
    }

    getVersion(versiontype: VersionTypes): Observable<IVersion> {
        let versionUrl: string = null;
        switch (versiontype) {
        case VersionTypes.api:
        {
            versionUrl = `${this.config.apiUrl}/version.json`;
            break;
        }
        case VersionTypes.ui:
        {
            versionUrl = `version.json`;
            break;
        }

        }
        return this.http.get<IVersion>(versionUrl);

    }
}