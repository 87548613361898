import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
    selector: "app-button",
    templateUrl: "./button.component.html",
    styleUrls: ["./button.component.css"]
})
export class ButtonComponent implements OnInit {

    @Input() disabled: boolean;
    @Input() text: string;
    @Input() color: string;
    @Input() small: boolean;
    @Input() routerLink: any;
    @Output() buttonClick = new EventEmitter<void>();

    constructor() { }

    ngOnInit() {
    }

    public submit(): void {
        if (!this.disabled) {
            this.buttonClick.emit();
        }
    }
}
