import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AgentNavigationComponent} from './agents-navigation.component';
import {RouterModule} from "@angular/router";

@NgModule({
    imports: [
        CommonModule,
        RouterModule
    ],
    declarations: [
        AgentNavigationComponent],
    exports: [AgentNavigationComponent],
})
export class AgentsNavigationModule {
}