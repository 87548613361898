import { Component, OnInit } from "@angular/core";
import { NotificationService } from "../../utils/notification.service"

import { LogoutService } from "./logout.service";
import { AuthService } from "app/auth/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { forkJoin } from "rxjs";

declare var $: any;

@Component({
    selector: "sa-logout",
    template: `
<div id="logout" (click)="showPopup()" class="btn-header transparent pull-right">
        <span> <a routerlink="/auth/login" title="{{ 'app.shared.user.logout.sign-out' | translate }}" data-action="userLogout"
                  attr.data-logout-msg="{{ 'app.shared.user.logout.you-can-improve-your-security' | translate }}"><i
          class="fa fa-sign-out"></i></a> </span>
    </div>
  `,
    styles: []
})
export class LogoutComponent implements OnInit {

    constructor(
        private notificationService: NotificationService,
        private authService: AuthService,
        private translate: TranslateService,
    ) { }

    showPopup() {
        forkJoin([
            this.translate.get('app.shared.labels.yes'),
            this.translate.get('app.shared.labels.no'),
            this.translate.get('app.shared.user.logout.logout'),
            this.translate.get('app.shared.user.logout.you-can-improve-your-security'),
        ]).subscribe((translations: string[]) => {
            const localizedYesBtn = translations[0];
            const localizedNoBtn = translations[1];
            const localizedLogoutTitle = translations[2];
            const localizedNotificationImproveSecurity = translations[3];

            this.notificationService.smartMessageBox({
                title: `<i class='fa fa-sign-out txt-color-orangeDark'></i> ${localizedLogoutTitle} <span class='txt-color-orangeDark'><strong>${$("#show-shortcut").text()}</strong></span> ?`,
                content: localizedNotificationImproveSecurity,
                buttons: `[${localizedNoBtn}][${localizedYesBtn}]`
            }, (ButtonPressed) => {
                if (ButtonPressed == localizedYesBtn) {
                    this.logout();
                }
            }); 
        });
    }

    logout() {
        this.authService.logout()
    }

    ngOnInit() {

    }



}
