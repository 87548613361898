import { Component, Input, ChangeDetectorRef, OnInit } from "@angular/core";
import { ReportService } from "app/services/report.service";
import { SlotGameLine } from "app/data/entities/slot-game-line";
import { SlotGameScatter } from "app/data/entities/slot-game-scatter";
import { DataSource } from "@angular/cdk/collections";
import { SimpleDataSource } from "app/data/datasets/simple.datasource";
import { Config } from "app/config/config.service";
import { FormatHelper } from "app/shared/utils/format-helper";
import { RowIterator } from "app/data/row-iterator";
import { PlayerGameService } from "app/generic-list/custom-actions/player-game-actions/player-game.service";
import { Subject } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { SideBet, SlotGameDetail } from "app/data/entities/slot-game-detail";
import { SlotGameBonus } from "app/data/entities/slot-game-bonus";
import { SlotGameFeatureGuarantee } from "app/data/entities/slot-game-feature-guarantee";
import { AppLanguageService } from "app/services/appLanguage.service";
import { error } from "protractor";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "app-slots-details-public",
    templateUrl: "slots-details.public.component.html",
    styleUrls: ["slots-details.public.component.css"]
})
export class SlotsDetailsPublicComponent implements OnInit {
    @Input() set tokenhash(value: string) {
        if (value) {
            this._token = value;
        }
    }

    @Input() set datahash(value: string) {
        if (value) {
            this._rowData = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }
    private _rowData: string;
    private _token: string;

    public displayedColumns: string[] = ["number", "occurrences", "payoutTypeId", "combination", "basePayoutAmount", "totalMultiplier", "payout", "payoutName", "line"];
    public displayedScattersColumns: string[] = ["scatterId", "basePayoutAmount", "totalMultiplier", "totalScatterPayoutAmount", "jackpot", "payoutName", "freeSpins"];
    public displayedSidebetsColumns: string[] = ["featureContribution", "featurePayout", "featureType"];
    public displayedFullWheelColumns: string[] = ["wheel"];
    public displayedBonusColumns: string[] = ["gameIdx", "gameDesc", "baseAmount", "multiplier", "bonusPayout", "freeSpin"];
    public displayedFeatureGuaranteeColumns: string[] = ["played", "spins", "percent", "sidebet", "featuretrigger", "prize"];

    public lines: Subject<SlotGameLine[]>;
    public scatters: Subject<SlotGameScatter[]>;
    public sidebets: Subject<SideBet[]>;
    public wheelDetails: Subject<SlotGameLine[]>;
    public bonusDetails: Subject<SlotGameBonus[]>;
    public featureGuaranteeDetails: Subject<SlotGameFeatureGuarantee[]>;
    

    public datasource: DataSource<SlotGameLine>;
    public datasourceScatter: DataSource<SlotGameScatter>;
    public datasourceGameWheelHistory: DataSource<SlotGameLine>;
    public datasourceGameBonus: DataSource<SlotGameBonus>;
    public datasourceFeatureGuarantee: DataSource<SlotGameFeatureGuarantee>;

    private lastLoadedSlot: SlotGameDetail = null;
    public bShowScatter: boolean = false;
    public bShowBonus: boolean = false;
    public bShowFeature: boolean = false;
    public bShowSidebet: boolean = false;
    public bShowAWPGame: boolean = true;
    public bShowPaysAny: boolean = false;
    public bShowSpinNotes: boolean = false;
    public bShowWheelbox: boolean = false;
    public bShowLinesInfo: boolean = false;
    public bShowMessageError: boolean = false;
    public spinNotesObject: object = null;
    public wheelbox: object = null;
    public currencyCode: string;
    public errorCode: string="";
    public currencyClassification: string;

    public isGlobalGameDetail: boolean = false;
    public globalGameDetailUrl: SafeResourceUrl;

    readonly IN_CASINO_GAMES_HISTORY_REPORT: number = 0;
    readonly GAMES_HISTORY_REPORT_GLOBAL_SERVICE: number = 1;

    constructor(
        private reportService: ReportService,
        private config: Config,
        private playerGameService: PlayerGameService,
        private cd: ChangeDetectorRef,
        private translate: TranslateService,
        public appLangService: AppLanguageService,
        private sanitizer: DomSanitizer
    ) {
        this.isGlobalGameDetail = false;
        const url = "";
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);     
    }

    ngOnInit(): void {
        this.lines = new Subject();
        this.scatters = new Subject();
        this.sidebets = new Subject();
        this.wheelDetails = new Subject();
        this.bonusDetails = new Subject();
        this.featureGuaranteeDetails = new Subject();
        this.spinNotesObject = null;
        this.wheelbox = null;
        this.translate.currentLang = '';
        this.currencyCode = '';
    }

    reloadSlotGameDetails() {
        if (this.lastLoadedSlot != null) {

            this.bShowScatter = (this.lastLoadedSlot.scatters != null && this.lastLoadedSlot.scatters.length > 0);
            this.bShowSidebet = ([this.lastLoadedSlot.sidebet].length > 0 && [this.lastLoadedSlot.sidebet][0] != null);
            this.bShowBonus = (this.lastLoadedSlot.bonusgameinfo != null && this.lastLoadedSlot.bonusgameinfo.length > 0);
            this.bShowFeature = (this.lastLoadedSlot.featureguaranteeinfo != null && this.lastLoadedSlot.featureguaranteeinfo.length > 0);
            this.bShowSpinNotes = (this.lastLoadedSlot.spinnotes != null && this.lastLoadedSlot.spinnotes.length > 0);

            this.wheelDetails.next(this.lastLoadedSlot.wheelinfos);

            this.wheelbox = this.lastLoadedSlot.wheelinfos;

            this.isGlobalGameDetail = (this.lastLoadedSlot.gamesHistoryReportProviderId === this.GAMES_HISTORY_REPORT_GLOBAL_SERVICE ? true : (this.lastLoadedSlot.gamesHistoryReportProviderId === this.IN_CASINO_GAMES_HISTORY_REPORT ? false : false));
            const url = this.lastLoadedSlot.globalGameDetailsUrl;
            this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

            this.lines.next(this.lastLoadedSlot.lines);
            this.scatters.next(this.lastLoadedSlot.scatters);
            this.sidebets.next([this.lastLoadedSlot.sidebet]);
            this.bonusDetails.next(this.lastLoadedSlot.bonusgameinfo);
            this.featureGuaranteeDetails.next(this.lastLoadedSlot.featureguaranteeinfo);
            this.spinNotesObject = this.formatSpinNotes();
            this.currencyClassification = this.lastLoadedSlot.currencyClassification;
        }
    }

    showPreviousItem() {
        this.playerGameService.back();
    }

    showNextItem() {
        this.playerGameService.forward();
    }

    get gameLinesDatasource(): DataSource<SlotGameLine> {
        this.reloadSlotGameDetails();
        return new SimpleDataSource(this.lines.asObservable());
    }

    get gameScattersDatasource(): DataSource<SlotGameScatter> {
        this.reloadSlotGameDetails();
        return new SimpleDataSource(this.scatters.asObservable());
    }

    get gameSidebetsDatasource(): DataSource<SideBet> {
        this.reloadSlotGameDetails();
        return new SimpleDataSource(this.sidebets.asObservable());
    }

    get gameWheelHistoryDatasource(): DataSource<SlotGameLine> {
        this.reloadSlotGameDetails();
        return new SimpleDataSource(this.wheelDetails.asObservable());
    }

    get gameBonusDataSource(): DataSource<SlotGameBonus> {
        this.reloadSlotGameDetails();
        return new SimpleDataSource(this.bonusDetails.asObservable());
    }

    get gameFeatureGuaranteeDataSource(): DataSource<SlotGameFeatureGuarantee> {
        this.reloadSlotGameDetails();
        return new SimpleDataSource(this.featureGuaranteeDetails.asObservable());
    }


    getGameData() {
        const slotGameDetail = this.reportService.getSlotGameDetailEncrypted(encodeURIComponent(this._rowData),encodeURIComponent(this._token) );

        slotGameDetail.subscribe((slot: SlotGameDetail) => {
            this.translate.currentLang = slot.languagecode ? slot.languagecode : "en";
            this.translate.setDefaultLang(this.translate.currentLang);
            this.showSpinDetailSections();
            this.bShowAWPGame = slot.allwayspayGame === false;
            this.bShowPaysAny = slot.paysAny === true;
            this.lastLoadedSlot = slot;
            this.currencyCode = slot.currencycode;
            this.isGlobalGameDetail = false;
            this.currencyClassification = slot.currencyClassification;
            this.reloadSlotGameDetails();
            this.cd.detectChanges();
        },
        err => {
            this.translate.currentLang = "en";
            this.translate.setDefaultLang(this.translate.currentLang);
            this.bShowMessageError = true;
            this.errorCode =  err.status.toString();
            this.reloadSlotGameDetails();
            this.cd.detectChanges();
        });
    }

    public getImgUrl(url: string): string {
        return this.config.imageCdnUrl + url;
    }

    public getNumber(line: SlotGameLine): string {
        return line.number > 0 ? line.number.toString() : "";
    }

    public toCurrency(amount: number): string {
        return FormatHelper.currency(amount, this.currencyCode, this.translate.currentLang, this.currencyClassification);
    }
    
    public toDateTime(value: any): string {
        return FormatHelper.shortDateAndTime(value, this.translate.currentLang);
    }

    public getGameName(value: any): string {
        return this.translate.instant(value);
    }

    public showYesNo(value: boolean): string {
        return value ? this.translate.instant('app.shared.labels.yes') : this.translate.instant('app.shared.labels.no');
    }

    public formatSpinNotes(): object[] {
        if (this.bShowSpinNotes){
            return JSON.parse(this.lastLoadedSlot.spinnotes);
        }
    }


    public getMessageError(error:string):string{
        this.bShowMessageError = true;
        if (error)
        {
        switch (error){
            case "400":
                return "app.shared.labels.tokenExpired";
                break;
            case "404":
                return 'app.casinos.generic-list.error-no-data';
                break;
            default:
                return "app.auth.login.error-try-again-later";
        }
        }
      
    }


    private showSpinDetailSections(){
        this.bShowWheelbox = true;
        this.bShowLinesInfo = true;
    }
}
