import { AppLangSwitcherComponent } from "./app-lang-switcher.component";
import { TranslateModule } from "@ngx-translate/core";
import { NgModule } from "@angular/core";
import { MaterialModule } from "../../../../material.module";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        CommonModule,
        
        MaterialModule,
        TranslateModule,
    ],
    declarations: [
      AppLangSwitcherComponent
    ],
    exports: [
      AppLangSwitcherComponent
    ]
  })
  export class AppLangSwitcherModule{}
  