
import {map} from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

import { Observable, of } from "rxjs";

import { Config } from "../config/config.service";
import { IAgent } from "../data/entities/agent";
import { ConstantsService } from "app/shared/utils/constants.service";
import { IAdjustCredits } from "app/data/dto/adjustCredit";
import { BroadcasterService } from "app/shared/utils/broadcaster.service";

@Injectable()
export class AgentsService {

    private agentUrl = `${this.config.apiUrl}/api/agent`;
    private agentsUrl = `${this.config.apiUrl}/api/agents`;
    private casinoAgentUrl = `${this.config.casinoManagementServiceUrl}/api/agent`;

    constructor(
        private http: HttpClient,
        private config: Config,
        private constantsService: ConstantsService,
        private broadcasterService: BroadcasterService) {

    }

    public getSubAgents(agentId: string, all = false): Observable<IAgent[]> {
        return this.http.get<IAgent[]>(this.agentsUrl, {
            params: new HttpParams().set("agentId", agentId).set("includeSubAgents", String(all))
        });
    }

    public getAgent(agentId: string): Observable<any> {
        return this.http.get<IAgent>(`${this.agentUrl}?agentId=${agentId}`).pipe(
            map(res => {
                this.broadcasterService.broadcast(this.constantsService.ADJUST_CREDIT_EVENT, res);
                return res;
            }));
    }

    public getAgentClaims(agentId: string): Observable<any> {
        return this.http.get<IAgent>(`${this.agentUrl}?agentId=${agentId}&includeClaims=true`);
    }

    public insertAgent(data: any): Observable<string> {
        data["countryId"] = this.config.defaultCountryId;
        data["timezoneId"] = this.config.defaultTimeZoneId;
        return this.http.post(this.agentUrl, data, { responseType: "text" }).pipe(map(res => res.substr(1, res.length - 2)));
    }

    public updateAgent(data: any): Observable<string> {
        data["countryId"] = this.config.defaultCountryId;
        data["timezoneId"] = this.config.defaultTimeZoneId;
        return this.http.put(this.agentUrl, data, { responseType: "text" });
    }

    public setAgentCredits(agentId: string, amount: number): Observable<IAdjustCredits> {
        const roundedValue = (+amount).toFixed(2);
        return this.http.put<IAdjustCredits>(`${this.agentUrl}/adjustcredit?agentId=${agentId}&amount=${roundedValue}`, null);
    }

    public patchAgent(agentId: string, fields: Map<string, any>): Observable<string> {
        const patch = [];

        fields.forEach((value: string, key: string) => {
            patch.push({ op: "replace", path: "/" + key, value: value });
        });

        return this.http.patch(this.agentUrl,
            patch,
            {
                headers: new HttpHeaders().set("Content-Type", "application/json-patch+json"),
                responseType: "text",
                params: new HttpParams().set("Id", agentId),
            });
    }

    public logoutPlayers(agentId: string, includeSubAgents: boolean) : Observable<any> {
        return this.http.put(`${this.casinoAgentUrl}/${agentId}/player/logout?includeSubAgents=${includeSubAgents}`, { responseType: "text" });
    }
}
