import { OnDestroy, Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef  } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Observable ,  Subscription } from "rxjs";
import { NavigationService } from "app/services/navigation.service";
import { IAgent } from "app/data/entities/agent";
import { BroadcasterService } from "app/shared/utils/broadcaster.service";
import { ConstantsService } from "app/shared/utils/constants.service";
import { AccountManager } from "../../utils/account-manager";
import { AuthService } from "app/auth/auth.service";
import { TokenManager } from "app/shared/utils/token-manager";
import { NavigationOptionsService } from "app/services/navigation-options.service";
import { FormControl } from '@angular/forms';
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "sa-navigation",
    templateUrl: "./navigation.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["navigation.component.css"]
})
export class NavigationComponent implements OnInit, OnDestroy {

    public agents: IAgent[];
    public currentAgentId: string;
    public currentPath: string;
    public loadingAgentId: string;
    private subscription: Subscription = new Subscription();

    @ViewChild("leftPanel") navigationView: ElementRef;

    constructor(
        private navigationService: NavigationService,
        private navigationOptionsService: NavigationOptionsService,
        private router: Router,
        private cd: ChangeDetectorRef,
        public tokenManager: TokenManager,
        private broadcasterService: BroadcasterService,
        private constantsService: ConstantsService,
        private authService: AuthService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {

        this.registerAgentChangeEvent();
        this.registerAgentAddedAndUpdated();
        this.registerRoutingEvent();

        this.agents = [];
        this.loadingAgentId = "";

        const agentId = this.tokenManager.getAccountInfo().agentId;
        const urlAgentId = this.navigationService.getAgentIdFromUrl(this.router.url);
        this.currentPath = this.navigationOptionsService.getValidUrlPerAgent(this.router.url);

        if (!agentId) {
            this.authService.logout();
        }
        if (agentId === urlAgentId || !urlAgentId) {
            this.navigationService.getAgent(agentId).subscribe(
                result => this.updateComponent(agentId)
            );
        } else {
            this.navigationService.getAgentsTree(urlAgentId).subscribe(result => {
                this.navigationService.openAgentsInTree(urlAgentId, result);
                this.updateComponent(urlAgentId);
            },
                err => {
                    this.authService.redirectToRootPage();
                });
        }
    }

    private registerRoutingEvent() {
        this.subscription.add(this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const nextAgentId = this.navigationService.getAgentIdFromUrl(event.url);
                this.currentPath = this.navigationOptionsService.getValidUrlPerAgent(this.router.url);
                if (this.currentAgentId === nextAgentId) {
                    return;
                }
                this.loadingAgentId = nextAgentId;
                if (this.navigationService.isAgentInTree(nextAgentId)) {
                    if (this.navigationService.isAgentLoaded(nextAgentId)) {
                        this.navigationService.openAgentsInTree(nextAgentId);
                        this.updateComponent(nextAgentId);
                    } else {
                        this.navigationService.getAgent(nextAgentId).subscribe(
                            result => this.updateComponent(nextAgentId)
                        );
                    }
                } else {
                    this.navigationService.getAgentsTree(nextAgentId).subscribe(() => {
                        this.broadcasterService.broadcast(this.constantsService.AGENT_TREE_LOAD_EVENT, nextAgentId);
                        this.navigationService.openAgentsInTree(nextAgentId);
                        this.updateComponent(nextAgentId);
                    },
                    err => {
                        this.authService.redirectToRootPage();
                    });
                }
            }
        })
        );
    }

    private registerAgentChangeEvent() {
        this.subscription.add(
            this.broadcasterService.on<string>(this.constantsService.AGENT_CHANGE_EVENT)
                .subscribe(agentId => {
                    this.navigationService.toggleAgentInTree(agentId);
                    this.cd.markForCheck();
                    this.updateBackground();
                })
        );
    }

    private registerAgentAddedAndUpdated() {
        this.subscription.add(
            this.broadcasterService.on<string>(this.constantsService.AGENT_ADDED_EVENT)
                .subscribe(agentId => {
                    this.navigationService.getAgent(agentId).subscribe(
                        result => this.updateComponent(agentId)
                    );
                })
        );
        this.subscription.add(
            this.broadcasterService.on<IAgent>(this.constantsService.AGENT_UPDATED_EVENT)
                .subscribe(agent => {
                    this.navigationService.updateLocalAgentName(agent.id, agent.name);
                    this.updateComponent(agent.id);
                })
        );
    }

    private updateComponent(agentId: string) {
        this.agents = this.navigationService.getLocalAgentsList();
        this.currentAgentId = agentId;
        this.loadingAgentId = "";
        this.cd.markForCheck();
        this.updateBackground();
    }

    private updateBackground() {
        document.body.style.background = "#1e445f";
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
