import { Injectable, OnDestroy } from "@angular/core";
import { Config } from "app/config/config.service";

import { ICountry } from "app/data/entities/country";
import { ConstantsService } from "app/shared/utils/constants.service";
import { HttpClient } from "@angular/common/http";
import { LocalStorageService } from "app/shared/utils/local-storage.service";
import { CommonService } from "./common.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Observable, Observer, Subscription } from "rxjs";

@Injectable()
export class CountriesService extends CommonService<ICountry> implements OnDestroy {
    private cachedTranslatedCountries: ICountry[] = [];
    private subscriptions: Subscription = new Subscription();

    constructor(
        httpClient: HttpClient,
        localStorageService: LocalStorageService,
        config: Config,
        constantsService: ConstantsService,
        private translate: TranslateService,
    ) {
        super(
            httpClient,
            localStorageService,
            `${config.apiUrl}/api/country`,
            constantsService.LS_COUNTRIES_KEY
        );
        this.subscriptions.add(
            this.translate.onLangChange.subscribe(async (_: LangChangeEvent) => {
                this.cachedTranslatedCountries = await this.translateCountries(this.cachedTranslatedCountries);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    async translateCountries(countries: ICountry[]): Promise<ICountry[]> {
        for (let country of countries) {
            try {
                country.name = await this.translate.get(`app.shared.countries.${country.id.toLowerCase()}`).toPromise();
            } catch (err) {
                console.log(err);
            }
        }

        return countries;
    }

    public getList(): Observable<ICountry[]> {
        return new Observable((observer: Observer<ICountry[]>) => {
            this.subscriptions.add(
                super.getList().subscribe(async (countries: ICountry[]) => {
                    this.cachedTranslatedCountries = await this.translateCountries(countries);

                    observer.next(this.cachedTranslatedCountries);
                    observer.complete();
                })
            );
        });
    }
}
