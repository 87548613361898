import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router, NavigationEnd, ActivatedRouteSnapshot } from "@angular/router";
import { NavigationService } from "app/services/navigation.service";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { Subscription } from "rxjs";

@Component({
    selector: "sa-route-breadcrumbs",
    template: `
        <ol class="breadcrumb">
          <li *ngFor="let item of items">
            <a (click)="goToUrl(item.link)">{{item.title}}</a>
          </li>
        </ol>
  `,
    styles: []
})

export class RouteBreadcrumbsComponent implements OnInit, OnDestroy {
    private agentId: string;
    public items: [{ title, link }];
    private subscriptions: Subscription = new Subscription();

    constructor(
        private activeRoute: ActivatedRoute,
        private router: Router,
        private navigationService: NavigationService,
        private translate: TranslateService,
    ) {
    }

    ngOnInit() {
        this.items = this.extractItemsFromSnapshot(this.activeRoute.snapshot, []);

        this.subscriptions.add(
            this.router.events
                .subscribe(event => {
                    if (event instanceof NavigationEnd) {
                        this.agentId = this.navigationService.getAgentIdFromUrl(this.router.url);
                        this.items = this.extractItemsFromSnapshot(this.activeRoute.snapshot, []);
                    }
                })
        );

        this.subscriptions.add(
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                if (this.activeRoute.snapshot == null) {
                    return;
                }

                this.items = this.extractItemsFromSnapshot(this.activeRoute.snapshot, []);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    goToUrl(itemLink) {
        if (itemLink === "root") { this.router.navigate(["/"]); return; }
        if (itemLink != null && Array.isArray(itemLink)) {
            if (itemLink.length >= 2) {
                var itm = itemLink[1].toString().toLowerCase();
                if (itm.indexOf("edit/:") >= 0 || itm.indexOf(":posid/users/:posuserid/update") >= 0) {
                    return;
                }
            }
        }
        if (!this.agentId) {
            this.agentId = this.navigationService.getAgentIdFromUrl(this.router.url);
        }
        const relativeUrl = [this.agentId].concat(itemLink);
        this.router.navigate(relativeUrl);
    }

    extractItemsFromSnapshot(activeRouteSnapshot, container): [{ title, link }] {
        this._appendRecursiveFlattenObj(container, activeRouteSnapshot.children[0], []);
        return container;
    }

    _appendRecursiveFlattenObj(items, element, link) {
        this.translate.get(element.data.pageTitle).subscribe((localizedPageTitle: string) => {
            if (element.routeConfig.path) {
                link.push(element.routeConfig.path);
                items.push({ title: localizedPageTitle, link: link.slice(0) });
            }
            if (element && element.children && element.children.length > 0) {
                this._appendRecursiveFlattenObj(items, element.children[0], link);
            }
        });

        return false;
    }
}
