import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Config } from "app/config/config.service";
import { IBalance } from "app/data/entities/balance/balance";
import { IBalanceTransfer } from "app/data/entities/balance/balanceTranfer";


@Injectable()
export class MulticashBalanceService {

    private url = `${this.config.casinoManagementServiceUrl}/api/potBalance`;
    private claimAgentId: String;

    constructor(private http: HttpClient,
        private config: Config) {
    }

    public getMulticashBalances(agentId: string): Observable<IBalance[]> {
        const params = new HttpParams().set("agentId", agentId);

        return this.http.get<IBalance[]>(`${this.url}`, { params });
    }

    public checkParentBalance(agentId: string, amount:string){
        const params = new HttpParams().set("childAgentId", agentId).set("amount", amount);
        return this.http.get(`${this.url}/check`, { params });
    }

    public checkBalance(agentId: string,amount:string) {
        const params = new HttpParams().set("agentId", agentId).set("amount", amount);
        return this.http.get(`${this.url}/check`, { params });
    }

    public checkBalanceWithCurrency(agentId: string, amount: string, currency: string) {
        const params = new HttpParams().set("agentId", agentId).set("amount", amount).set("currency",currency);
        return this.http.get(`${this.url}/check`, { params });
    }

    public deleteMulticashBalance(id: string):Observable<any>{

        return  this.http.delete(`${this.url}/${id}`);
    }

    public assignMulticashBalance(agentId: string,balanceIid: string):Observable<any>{



        return  this.http.put(`${this.url}/assign`,{ balanceIid,agentId });
    }
    
    public assignDefaultMulticashBalance(agentId: string):Observable<any>{


        return  this.http.put(`${this.url}/assign`,{ agentId });
    }

    public getMulticashBalance(agentId: string): Observable<IBalance> {
        const params = new HttpParams().set("agentId", agentId);

        return this.http.get<IBalance>(`${this.url}`, { params });
    }

    public editMulticashBalance(balance: IBalance) {
        return this.http.put<IBalance>(`${this.url}`, balance);
    }

    public createMulticashBalance(balance: IBalance) {
        return this.http.post<IBalance>(`${this.url}`, balance);
    }

    public balanceTransfer(balanceTransfer: IBalanceTransfer) {
        return this.http.patch<IBalanceTransfer>(`${this.url}`, balanceTransfer);
    }
}
