import {Injectable} from "@angular/core";

import { JwtHelperService } from "@auth0/angular-jwt";
import {ConstantsService} from "app/shared/utils/constants.service";
import { SessionStorageService } from "./session-storage.service";

@Injectable()
export class TokenManager {

    private jwtHelper = new JwtHelperService();

    constructor(private constantsService: ConstantsService, private sessionStorageService: SessionStorageService) {    }

    public store(content: Object) {
        if (!content) {
            return null;
        }
        const ttl = new Date();

        ttl.setSeconds(ttl.getSeconds() + content["expires_in"]);
        const currentTime: number = ttl.getTime();
        this.sessionStorageService.setItem(this.constantsService.APP_TOKEN, JSON.stringify({ttl: currentTime, content}));
    }

    private retrieve() {
        const storedToken: string = this.sessionStorageService.getItem(this.constantsService.APP_TOKEN);
        if (!storedToken) {
            return null;
        }
        return storedToken;
    }

    public retrieveToken() {
        const currentTime: number = (new Date()).getTime();
        let token = null;
        try {
            const storedToken = JSON.parse(this.retrieve());
            if (!storedToken || storedToken.ttl < currentTime) {
                return null;
            }

            token = `${storedToken.content.token_type} ${storedToken.content.access_token}`;
        } catch (err) {
            return null;
        }
        return token;
    }

    public removeToken() {
        try {
            this.sessionStorageService.removeItem(this.constantsService.APP_TOKEN);
        } catch (err) {
        }
    }

    public getValueFromToken(value: any): any {
        const token = JSON.parse(this.retrieve());
        if (token) {
            const decodeToken = this.jwtHelper.decodeToken(token.content.access_token);
            return decodeToken[value];
        }

        return "";
    }
    getAccountInfo(): AccountInfo {
        const account = new AccountInfo;
        account.id = this.getValueFromToken(this.constantsService.TOKEN_USER_ID);
        account.agentId = this.getValueFromToken(this.constantsService.AUTH_CLAIM);
        account.name = this.getValueFromToken(this.constantsService.TOKEN_USER_NAME);
        return account;
    }
}

export class AccountInfo {
    id: string;
    agentId: string;
    name: string;
}
