
import {throwError as observableThrowError, Observable} from "rxjs";

import {catchError, timeout, tap, map} from "rxjs/operators";
import {Config} from "../config/config.service";
import {Injectable} from "@angular/core";
import {Headers, Http, RequestOptions, Response} from "@angular/http";

import {IAuth} from "./auth";
import {TokenManager} from "../shared/utils/token-manager";
import {Router} from "@angular/router";
import { NavigationService } from "app/services/navigation.service";
import { NavigationOptionsService } from "app/services/navigation-options.service";

@Injectable()
export class AuthService {
    isLoggedIn = false;

    constructor(private http: Http,
                private config: Config,
                private router: Router,
                private tokenManager: TokenManager,
                private navigationService: NavigationService,
                private navigationOptionsService: NavigationOptionsService) {

    }

    public login(userName: string, password: string, granType: string): Observable<IAuth> {

        const headers = new Headers({"Content-Type": "application/x-www-form-urlencoded"});
        const options = new RequestOptions({headers: headers});
        const body = `password=${encodeURI(password)}&grant_type=${encodeURI(granType)}`
            + `&username=${encodeURI(userName)}&client_id=ui&client_secret=secret`;
        const url = `${this.config.identityServiceUrl}/connect/token`;
        return this.http.post(url, body, options).pipe(
            map((response: Response) => <IAuth> response.json()),
            tap(data => this.tokenManager.store(data)),
            timeout(this.config.apiTimeOut),
            catchError(this.handleError),);
    }

    public logout(): void {
        this.isLoggedIn = false;
        this.navigationService.clearLocalNavigationData();
        this.tokenManager.removeToken();
        this.router.navigate(["/auth/login"]);
    }

    public redirectToRootPage(): void {
        const rootId = this.tokenManager.getAccountInfo().agentId;
        const rootPage = this.navigationOptionsService.getLandingRoute(rootId);
        this.router.navigate([`${rootId}/${rootPage}`]);
    }


    private handleError(error: Response) {
        return observableThrowError(error || "Server error");
    }
}
