import './lib'

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';
import { ConfigService} from './app/config/config.service'
import 'hammerjs';

if (environment.production) {
  enableProdMode();
}

ConfigService.loadInstance().then(() => {
	platformBrowserDynamic().bootstrapModule(AppModule);
});


