import { ApplicationRef, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HttpService } from "app/shared/utils/http.service";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpModule } from "@angular/http";
import { RouterModule } from "@angular/router";
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from "@angular/common/http";
/*
 * Platform and Environment providers/directives/pipes
 */
import { routing } from "./app.routing";
// App is our top level component
import { AppComponent } from "./app.component";
import { APP_RESOLVER_PROVIDERS } from "./app.resolver";
import { AppState, InternalStateType } from "./app.service";
// Core providers
import { CoreModule } from "./core/core.module";
import { SmartadminLayoutModule } from "./shared/layout/layout.module";
import { SmartadminModule } from "./shared/smartadmin.module";
import { AuthGuard } from "app/auth-guard.service";
import { LoggedGuard } from "app/logged-guard.service";
import { TokenManager } from "app/shared/utils/token-manager";
import { RequestTrackingService } from "app/shared/utils/request-tracking.service";
import { AuthService } from "app/auth/auth.service";
import { ConstantsService } from "./shared/utils/constants.service";
import { LocalStorageService } from "./shared/utils/local-storage.service";
import { SessionStorageService } from "./shared/utils/session-storage.service";
import { Config, ConfigService } from "./config/config.service";
import { AgentsService } from "app/services/agents.service";
import { WalletService } from "app/services/wallet.service";
import { NavigationService } from "app/services/navigation.service";
import { NavigationOptionsService } from "app/services/navigation-options.service";
import { UserService } from "app/users/user.service";
import { CasinoService, CasinoAgentService } from "app/casinos/casino.service";
import { BroadcasterService } from "./shared/utils/broadcaster.service";
import { PosService } from "app/services/pos.service";
import { PlayerService } from "app/services/player.service";
import { AuthInterceptor } from "app/shared/utils/authInterceptor";
import { CountriesService } from "app/services/countries.service";
import { CurrencyService } from "app/services/currency.service";
import { LanguageService } from "app/services/language.service";
import { TimezonesService } from "app/services/timezones.service";
import { PosRolesService } from "app/services/pos-roles.service";
import { GenericListService } from "app/generic-list/generic-list.service";
import { MaterialModule } from "./material.module";
import { VersionService } from "app/services/version.service";
import {GameHistoryPublicModule } from "app/miscellaneous/game-history-public/game-history-public.module"
import { DialogsModule } from "app/dialogs/dialogs.module";
import { ClaimsService } from "app/services/claims.service";
import { RoleService } from "app/roles/role.service";
import { PasswordValidation } from "app/shared/custom-validations/password-validation";
import { AccountManager } from "app/services/permissions/account-manager";
import { ReportService } from "app/services/report.service";
import { PlayerGameService } from "app/generic-list/custom-actions/player-game-actions/player-game.service";
import { IpListService } from "app/iplist/iplist.service";

// i18n
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {MultiTranslateHttpLoader} from "ngx-translate-multi-http-loader";
import { AppLanguageService } from "./services/appLanguage.service";
//

// Application wide providers
const APP_PROVIDERS = [
    ...APP_RESOLVER_PROVIDERS,
    AppState
];

interface StoreType {
    state: InternalStateType;
    restoreInputValues: () => void;
    disposeOldHosts: () => void;
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    const config = ConfigService.get();
    const localizedComponents = ['game_strings'];

    return new MultiTranslateHttpLoader(httpClient, [
        {prefix: './assets/i18n/', suffix: '.json'},
        ...(localizedComponents.map(c => {
            return {prefix: `${config.casinoManagementServiceUrl}/api/localization/${c}/`, suffix: '.json'};
        }))
    ]);
}

/**
 * `AppModule` is the main entry point into Angular2";s bootstraping process
 */
@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        AppComponent
    ],
    imports: [ // import Angular's modules
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpModule,
        CoreModule,
        SmartadminLayoutModule,
        SmartadminModule,
        MaterialModule,
        DialogsModule,
        ReactiveFormsModule,
        BrowserModule,
        HttpClientModule,
        routing,
        GameHistoryPublicModule,
        TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient]
            }
        }),
    ],
    exports: [],
    providers: [ // expose our Services and Providers into Angular's dependency injection
        // ENV_PROVIDERS,
        HttpClientModule,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        APP_PROVIDERS,
        TranslateService,
        RouterModule,
        AuthGuard,
        TokenManager,
        AccountManager,
        RequestTrackingService,
        LoggedGuard,
        CountriesService,
        TimezonesService,
        PosRolesService,
        AuthService,
        AgentsService,
        WalletService,
        NavigationService,
        NavigationOptionsService,
        CurrencyService,
        LanguageService,
        UserService,
        PosService,
        ReportService,
        PlayerService,
        HttpService,
        ConstantsService,
        LocalStorageService,
        SessionStorageService,
        BroadcasterService,
        GenericListService,
        ClaimsService,
        RoleService,
        VersionService,
        PasswordValidation,
        PlayerGameService,
        IpListService,
        { provide: Config, useFactory: ConfigService.get },
        AppLanguageService
    ]
})
export class AppModule {
    constructor(public appRef: ApplicationRef, public appState: AppState) {
    }
}

