﻿import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { AccountManager } from "app/services/permissions/account-manager";

@Component({
    selector: "app-input",
    templateUrl: "./bonus.component.html",
    styleUrls: ["./bonus.component.css"]
})
export class BonusComponent<T> implements OnInit {

    public permissions: any;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
        //private permissions: any,
        public dialogRef: MatDialogRef<BonusComponent<T>>,
        public accountManager: AccountManager,
    ) { }

    public sendData(info: string) {
        this.dialogRef.close(info);
    }

    ngOnInit() {
        this.permissions = {
            "canDenyBonus": this.accountManager.canDenyBonus(undefined),
            "canRedeemBonus": this.accountManager.canRedeemBonus(undefined)
        };
    }
}
