
import {merge as observableMerge, BehaviorSubject, Observable} from 'rxjs';

import {map} from 'rxjs/operators';
import {DataSource} from "@angular/cdk/table";
import {MatPaginator, MatSort} from "@angular/material";

export interface Comparer<T> {
    compare(item: T, filter: string): boolean;
}

export class GenericDataSource<T> extends DataSource<T> {
    filterChange = new BehaviorSubject("");

    get filter(): string {
        return this.filterChange.value;
    }

    set filter(filter: string) {
        this.filterChange.next(filter);
    }

    public comparer: Comparer<T>;

    private _data: T[];

    get data(): T[] {
        return this._data;
    }

    set data(value: T[]) {
        this._data = value;
        this.dataChange.next(this._data);
    }

    private dataChange: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

    constructor(data: T[], private sort: MatSort, private paginator: MatPaginator = null, private primaryStringSortColumns: string[] = []) {
        super();
        this.data = data;
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    connect(): Observable<T[]> {
        const displayDataChanges = [
            this.dataChange,
            this.sort.sortChange,
            this.filterChange
        ] as any[];

        if (this.paginator != null) {
            displayDataChanges.push(this.paginator.page);
        }

        return observableMerge(...displayDataChanges).pipe(map(() => {
            if (this.paginator != null) {
                const data = this.getSortedData().slice();
                this.paginator.length = data.length;
                const startIndex = this.paginator.pageIndex * this.paginator.pageSize;
                return data.splice(startIndex, this.paginator.pageSize);
            }

            return this.getSortedData();
        }));
    }

    disconnect() {
    }

    /** Returns a sorted copy of the database data. */
    getSortedData(): T[] {
        const data = this.data.slice().filter((item: T) => {
            return this.comparer != null ? this.comparer.compare(item, this.filter.toLowerCase()) : true;
        });

        if (!this.sort.active || this.sort.direction == "") {
            return data;
        }

        return data.sort((a, b) => {
            let propertyA: number | boolean | string = "";
            let propertyB: number | boolean | string = "";

            [propertyA, propertyB] = [a[this.sort.active], b[this.sort.active]];

            let valueA, valueB;

            if (this.primaryStringSortColumns.includes(this.sort.active))
                {
                    valueA = String(propertyA).toLowerCase();
                    valueB = String(propertyB).toLowerCase();
                }
            else
                {
                    valueA = isNaN(+propertyA) ? propertyA : +propertyA;
                    valueB = isNaN(+propertyB) ? propertyB : +propertyB;
                }   

            return (valueA < valueB ? -1 : 1) * (this.sort.direction == "asc" ? 1 : -1);            
        });
    }
}