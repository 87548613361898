import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppLanguageService } from "../../../services/appLanguage.service";

declare var $: any;

@Component({
  selector: "sa-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  searchMobileActive = false;

  constructor(
    private router: Router,
    public translate: TranslateService,
    public appLangService: AppLanguageService,
  ) {
  }

  ngOnInit() {
  }

  toggleSearchMobile() {
    this.searchMobileActive = !this.searchMobileActive;
    $("body").toggleClass("search-mobile", this.searchMobileActive);
  }

  goToRoot() {
    this.router.navigate(["/"]);
  }

  onSubmit() {
    this.router.navigate(["/miscellaneous/search"]);
  }
}
