
import {throwError as observableThrowError, of as observableOf,  forkJoin ,timer as observableTimer,  Subscription ,  Unsubscribable as AnonymousSubscription,  Observable } from 'rxjs';
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from "@angular/core";
import { IBalance } from "app/data/entities/balance/balance";
import { LayoutService } from "../../layout/layout.service";
import { TokenManager, AccountInfo } from "app/shared/utils/token-manager";
import { AgentsService } from "app/services/agents.service";
import { IAgent } from "app/data/entities/agent";
import { BroadcasterService } from "app/shared/utils/broadcaster.service";
import { ConstantsService } from "app/shared/utils/constants.service";
import { Config } from "app/config/config.service";
import { TranslateService } from '@ngx-translate/core';
import { MulticashBalanceService } from "app/agents/multicash-balance/multicashBalance.service";
import { AccountManager } from "app/services/permissions/account-manager";
import {catchError} from "rxjs/operators";
@Component({
    selector: "sa-login-info",
    templateUrl: "./login-info.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ["./login-info.component.css"]
})
export class LoginInfoComponent implements OnInit, OnDestroy {

    public user: AccountInfo;
    public agent: IAgent;
    public currency: string;
    private subscription: Subscription = new Subscription();
    private timerSubscription: Subscription = new Subscription;
    public potBalance : IBalance;
    constructor(
        private tokenManager: TokenManager,
        private config: Config,
        private cd: ChangeDetectorRef,
        private layoutService: LayoutService,
        private agentsService: AgentsService,
        private broadcasterService: BroadcasterService,
        private constantServices: ConstantsService,
        private translate: TranslateService,
        public accountManager: AccountManager,
        private multicashBalanceService: MulticashBalanceService
        
    ) {
    }

    ngOnInit(): void {
        this.user = this.tokenManager.getAccountInfo();
        this.loadAgent();
        this.keepDataUpdated();
        this.regiterCreditAmountChange();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.timerSubscription.unsubscribe();
    }

    public loadAgent(): void {
        this.agentsService.getAgent(this.user.agentId).subscribe(
            result => {
                this.agent = result;
                this.cd.markForCheck();
            }
        );
    }

    public toggleShortcut(): void {
        this.layoutService.onShortcutToggle();
    }

    private regiterCreditAmountChange(): void {
        this.subscription.add(
            this.broadcasterService.on(this.constantServices.ADJUST_CREDIT_EVENT).subscribe(
                (agent: IAgent) => {
                    if (agent.id === this.user.agentId) {
                        this.agent = agent;
                        this.timerSubscription.unsubscribe();
                        this.keepDataUpdated();
                    }
                }
            )
        );
    }

    private keepDataUpdated(): void {
        this.timerSubscription = observableTimer(this.config.creditBalanceUpdateInterval)
            .subscribe(() => {
                this.loadAgent();
            });
    }
    
}
