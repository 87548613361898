
import {distinctUntilChanged, debounceTime, map} from "rxjs/operators";
import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Subject } from "rxjs";
import { GenericDataSource } from "../../../data/datasets/generic.datasource";
import { TranslateService } from "@ngx-translate/core";


@Component({
  selector: "app-list-search",
  templateUrl: "./list-search.component.html",
  styleUrls: ["./list-search.component.css"]
})
export class ListSearchComponent implements OnInit {
    @Input() dataSource: GenericDataSource<any>;
    @Output() filtered = new EventEmitter<GenericDataSource<any>>();
    public keyUp = new Subject<any>();

    constructor(
        private translate: TranslateService,
    ) {}

    ngOnInit() {
        this.keyUp.pipe(
            map(event => event.target.value),
            debounceTime(150),
            distinctUntilChanged(), )
            .subscribe((value) => {
                if (!this.dataSource) {
                    return;
                }
                this.dataSource.filter = value;
                this.filtered.emit(value);
            });
    }
}
