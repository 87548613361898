
import {mergeMap, map, filter} from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { FadeZoomInTop } from "../../animations/fade-zoom-in-top.decorator";

import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { RequestTrackingService } from "app/shared/utils/request-tracking.service";


@FadeZoomInTop()
@Component({
    selector: "app-main-layout",
    templateUrl: "./main-layout.component.html",
    styleUrls: ["./main-layout.component.css"]
})
export class MainLayoutComponent implements OnInit {
    public title: string;
    public moduleTitle: string;
    public navAgentGuid: string;

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private requestTrackingService: RequestTrackingService) {

    }

    public isLoading(): boolean {
        return this.requestTrackingService.isLoading();
    }

    ngOnInit() {
        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            }),
            filter((route) => route.outlet === "primary"),
            mergeMap((route) => route.data),)
            .subscribe((event) => {
                this.title = event["pageTitle"];
                this.moduleTitle = event["moduleTitle"];
            });
    }

}
