﻿import {NgModule, OnDestroy} from "@angular/core";
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatOptionModule,
    MAT_DATE_LOCALE,
    DateAdapter,
    MAT_DATE_FORMATS,
    MatPaginatorIntl,
} from "@angular/material";

import { 
    MomentDateAdapter,
    MAT_MOMENT_DATE_FORMATS,
} from "@angular/material-moment-adapter";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { PaginatorIntlService } from "./services/paginatorIntlService";
import { Subscription } from "rxjs";

export const DDMMYYYY_DATE_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@NgModule({
    exports: [
        MatAutocompleteModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatOptionModule,
    ],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'en-US'}, // default value does not matter - locale will be set to the appropriate one when application starts
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: DDMMYYYY_DATE_FORMATS},
        {
            provide: MatPaginatorIntl,
            useFactory: (translate) => {
                const service = new PaginatorIntlService();
                service.injectTranslateService(translate);
                return service;
            },
            deps: [
                TranslateService
            ]
        }
    ]
})
export class MaterialModule implements OnDestroy {
    private subscriptions: Subscription = new Subscription();

    constructor(
        private translate: TranslateService,
        private adapter: DateAdapter<any>
    ) {
        if (this.translate.currentLang != null) {
            this.adapter.setLocale(translate.currentLang);
        }

        // set Monday as first day
        this.adapter.getFirstDayOfWeek = () => { return 1; };

        this.subscriptions.add(
            this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
                this.adapter.setLocale(event.lang);
            })
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
