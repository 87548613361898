import {Commands} from "./commands";
import {Command} from "./command";

export class AdjustBalanceCommand extends Command {
    amount: number;
    userId: string;

    constructor() {
        super(Commands.AdjustBalance);
    }
}
