
import {map} from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Router } from "@angular/router";

import { Observable } from "rxjs";

import { Config } from "app/config/config.service";
import { IAgent } from "app/data/entities/agent";
import { TokenManager } from "app/shared/utils/token-manager";

@Injectable()
export class NavigationService {

    public localAgents: IAgent[];
    private navigationUrl = `${this.config.apiUrl}/api/navigation`;

    constructor(
        private http: HttpClient,
        private config: Config,
        private tokenManager: TokenManager,
        private router: Router) {
    }

    public clearLocalNavigationData(): void {
        this.localAgents = null;
    }

    public getLocalAgent(agentId: string, agentList: IAgent[] = null): IAgent {
        if (!agentList) {
            if (!this.localAgents) { return null; }
            agentList = this.localAgents;
        }
        for (const a of agentList) {
            if (a.id === agentId) {
                return a;
            } else if (a.children) {
                const agent = this.getLocalAgent(agentId, a.children);
                if (agent) {
                    return agent;
                }
            }
        }
        return null;
    }

    public updateLocalAgentName(agentId: string, newAgentName: string): void {
        const agent = this.getLocalAgent(agentId);
        agent.name = newAgentName;
    }

    public getLocalAgentsList(): IAgent[] {
        if (this.localAgents) {
            return this.localAgents;
        }
        return null;
    }

    public getAgentsTree(agentId: string): Observable<any> {
        return this.http.get<IAgent>(`${this.navigationUrl}/tree?agentId=${agentId}`).pipe(map(agent => {
            this.localAgents = new Array(agent);
            return this.localAgents;
        }));
    }

    public searchAgents(agentId: string, filter?: string): Observable<any> {
        return this.http.get<IAgent>(`${this.navigationUrl}/search?agentId=${agentId}&filter=${filter}`);
    }

    public getAgent(agentId: string): Observable<IAgent> {
        return this.http.get<IAgent>(`${this.navigationUrl}?id=${agentId}`).pipe(map(agent => {
            agent.open = true;
            if (this.localAgents && this.localAgents[0].id !== agent.id) {
                this.addChildren(this.localAgents[0].children, agent);
            } else {
                this.localAgents = new Array(agent);
            }

            return agent;
        }));
    }

    public isAgentInTree(agentId: string, agentList: IAgent[] = null): boolean {
        if (agentList === null ) {
            if (this.localAgents === null) { return false; }
            agentList = this.localAgents;
        }

        const agent = this.getLocalAgent(agentId, agentList);
        return agent !== null;
    }

    public openAgentsInTree(agentId: string, agentList: IAgent[] = null) {
        if (agentList === null ) {
            if (this.localAgents === null) { return; }
            agentList = this.localAgents;
        }

        for (const agent of agentList) {
            if (agent.id === agentId) {
                agent.open = true;
                return true;
            } else if (agent.children) {
                if (this.openAgentsInTree(agentId, agent.children)) {
                    agent.open = true;
                    return true;
                }
            }
        }
        return;
    }

    public toggleAgentInTree(agentId: string) {
        if (this.localAgents === null || !this.localAgents[0].children) { return; }

        for (const agent of this.localAgents[0].children) {
            let agentSelected = agent;
            if (agent.id !== agentId && agent.children) {
                agentSelected = this.getLocalAgent(agentId, agent.children);
            }

            if (agentSelected && agentSelected.id === agentId) {
                agentSelected.open = !agentSelected.open;
            } else {
                agent.open = false;
            }
        }
    }

    public isAgentLoaded(agentId: string): boolean {
        const agent = this.checkAgentExists(this.localAgents, agentId);
        if (agent) { return true; }
        return false;
    }

    public getAgentIdFromUrl(url: string = this.router.url): string {
        const urlArray = url.split("/");
        return urlArray[1] ? urlArray[1] : this.tokenManager.getAccountInfo().agentId;
    }

    private checkAgentExists(agents: IAgent[], agentId: string): IAgent {
        for (const agent of agents) {
            if (agent.id === agentId && ((agent.hasChildren && (agent.children && agent.children.length > 0)) || (!agent.hasChildren))) {
                return agent;
            } else if (agent.hasChildren && agent.children) {
                const foundAgent = this.checkAgentExists(agent.children, agentId);
                if (foundAgent) {
                    return foundAgent;
                }
            }
        }
        return null;
    }

    private addChildren(children: IAgent[], newAgent: IAgent): void {
        children.forEach((child, index, array) => {
            if (child["id"] === newAgent.id) {
                array[index] = newAgent;
                return;
            } else {
                if (child.children) {
                    this.addChildren(child.children, newAgent);
                }
            }
        });
    }
}
