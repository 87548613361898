﻿import { NgModule } from "@angular/core";
import { MaterialModule} from "app/material.module";

import { ConfirmationComponent } from "./confirmation/confirmation.component";
import { DialogComponent } from "./dialog/dialog.component";
import { InputComponent } from "./input/input.component";
import { BonusComponent } from "./bonus/bonus.component";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        MaterialModule,
        TranslateModule,
        CommonModule,
    ],
    declarations: [
        ConfirmationComponent,
        DialogComponent,
        InputComponent,
        BonusComponent
    ],
    entryComponents: [
        ConfirmationComponent,
        DialogComponent,
        InputComponent,
        BonusComponent
    ]
})
export class DialogsModule {
}
