import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClient, HttpParams } from "@angular/common/http";


import { Observable } from "rxjs";

import { Config } from "../config/config.service";

import { TokenManager } from "../shared/utils/token-manager";
import { ICasino, ICasinoAgent } from "../data/entities/casino";
import { Claim } from "app/data/entities/claim";
import { HttpHeaders } from "@angular/common/http";


@Injectable()
export class CasinoService {


    private url = `${this.config.casinoManagementServiceUrl}/api/casino`;
    private claimAgentId: String;

    constructor(private http: HttpClient,
        private config: Config,
        private tokenManager: TokenManager) {
    }
    public getAssignedCasinos(agentId: string): Observable<ICasino[]> {
        return this.http.get<ICasino[]>(this.url + "/assigned/" + agentId);
    }

    public getCasinos(): Observable<ICasino[]> {
        return this.http.get<ICasino[]>(this.url);
    }

    public updateCasino(casino: ICasino): Observable<any> {
        return this.http.patch(`${this.url}`, casino,
            {
            headers: new HttpHeaders().set("Content-Type", "application/json-patch+json"),
                responseType: "text"
            });
    }
    public createCasino(casino: ICasino): Observable<any> {
         return this.http.put(this.url, casino, { responseType: "text" });
    }
    public deleteCasino(id: string): Observable<any> {
        return this.http.delete(`${this.url}/${id}`, { responseType: "text" });
    }

    public getCasino(id: string): Observable<ICasino> {
        return this.http.get<ICasino>(`${this.url}/${id}`);
    }

}

@Injectable()
export class CasinoAgentService {


    private url = `${this.config.casinoManagementServiceUrl}/api/casinoagent`;
    private claimAgentId: String;

    constructor(private http: HttpClient,
        private config: Config,
        private tokenManager: TokenManager) {
    }

    public postCasinoAgent(agentId: string, assignedCasinos: string[]): Observable<any> {
        return this.http.post(`${this.url}/${agentId}`, assignedCasinos);
    }

    public getCasinoAgent(agentId: string): Observable<ICasinoAgent[]> {
        return this.http.get<ICasinoAgent[]>(`${this.url}/${agentId}`);
    }


}

