export class SharedReportDetail {
    gameName: string;
    account: string;
    betId: string;
    currency: string;
    betAmount: number;
    payout: number;
    betTime: string;
    roundId: string;

    constructor(
        gameName: string,
        account: string,
        betId: string,
        currency: string,
        betAmount: number,
        payout: number,
        betTime: string,
        roundId: string
    ){
        this.gameName = gameName;
        this.account = account;
        this.betId = betId;
        this.currency = currency;
        this.betAmount = betAmount;
        this.payout = payout;
        this.betTime = betTime;
        this.roundId = roundId;
    }
}
