import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-spinner",
  template: `
    <mat-progress-spinner
        id="app-spinner"
        [diameter]="40"
        [mode]="'indeterminate'">
    </mat-progress-spinner>
  `,
  styleUrls: ["./spinner.component.css"]
})
export class SpinnerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
