import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { MaterialModule} from "app/material.module";

import { BackButtonComponent } from "app/shared/components/back-button/back-button.component";
import { ButtonComponent } from "app/shared/components/button/button.component";
import { RouterModule } from "@angular/router";
import { FormatCurrency } from "app/shared/pipes/currencyPipe";
import { ListSearchComponent } from "app/shared/components/list-search/list-search.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        RouterModule, 
        CommonModule, 
        MaterialModule,
        TranslateModule
    ],
    declarations: [
        BackButtonComponent,
        ButtonComponent,
        FormatCurrency,
        ListSearchComponent
    ],
    exports: [
        BackButtonComponent, 
        ButtonComponent, 
        FormatCurrency, 
        ListSearchComponent
    ]
})
export class ShareComponentsModule { }
