﻿import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "rtg-confirmation",
    templateUrl: "./confirmation.component.html",
    styleUrls: ["./confirmation.component.css"]
})
export class ConfirmationComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private translate: TranslateService,
    ) { }
}
