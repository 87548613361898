
import {of as observableOf, Observable} from 'rxjs';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';


@Injectable()
export class DataResolver implements Resolve<any> {
    constructor() {

    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return observableOf({res: 'I am data'});
    }
}

// an array of services to resolve routes with data
export const APP_RESOLVER_PROVIDERS = [
    DataResolver
];
