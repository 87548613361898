import {Injectable} from "@angular/core";
import {CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot} from "@angular/router";

import {TokenManager} from "./shared/utils/token-manager";
import {AuthService} from "./auth/auth.service";
import { NavigationOptionsService } from "app/services/navigation-options.service";
import { ConstantsService } from "app/shared/utils/constants.service";


@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private tokenManager: TokenManager,
                private router: Router,
                private navigationOptionsService: NavigationOptionsService,
                public constantsService: ConstantsService,
                private authService: AuthService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        try {
            const token = this.tokenManager.retrieveToken();
            if (!token) { return this.gotoLoginPage(); }

            if (route.url.length === 0) {
                const agentId =  route.paramMap.get("agentId") ? route.paramMap.get("agentId") : this.tokenManager.getAccountInfo().agentId;
                this.router.navigate([`${agentId}/${this.navigationOptionsService.getLandingRoute(agentId)}`]);
                return true;
            } else {
                const agentId = route.paramMap.get("agentId");
                this.authService.isLoggedIn = true;
                if (agentId) {
                    return (this.constantsService.REGEXT_GUID.test(agentId));
                } else {
                    return true;
                }
            }

        } catch (error) {
            return this.gotoLoginPage();
        }
    }

    private gotoLoginPage() {
        this.authService.isLoggedIn = false;
        this.router.navigate(["/auth/login"]);
        return false;
    }
}
