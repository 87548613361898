import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";

import {HeaderModule} from "./header/header.module";
import {FooterComponent} from "./footer/footer.component";
import {NavigationModule} from "./navigation/navigation.module";
import {NavigationOptionsModule} from "./navigation-options/navigation-options.module";
import {RibbonComponent} from "./ribbon/ribbon.component";
import {LayoutSwitcherComponent} from "./layout-switcher.component";
import {MainLayoutComponent} from "./app-layouts/main-layout.component";
import {SmartadminWidgetsModule} from "../widgets/smartadmin-widgets.module";
import {EmptyLayoutComponent} from "./app-layouts/empty-layout.component";
import {RouterModule} from "@angular/router";
import {AuthLayoutComponent} from "./app-layouts/auth-layout.component";
import {BsDropdownModule, TooltipModule} from "ngx-bootstrap";
import {RouteBreadcrumbsComponent} from "./ribbon/route-breadcrumbs.component";
import {UtilsModule} from "../utils/utils.module";
import { MatProgressBarModule } from "@angular/material";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    imports: [
        CommonModule,
        HeaderModule,
        NavigationModule,
        NavigationOptionsModule,
        FormsModule,
        RouterModule,
        UtilsModule,
        SmartadminWidgetsModule,
        TooltipModule,
        BsDropdownModule,
        MatProgressBarModule,
        TranslateModule,
    ],
    declarations: [
        FooterComponent,
        RibbonComponent,
        LayoutSwitcherComponent,
        MainLayoutComponent,
        EmptyLayoutComponent,
        AuthLayoutComponent,
        RouteBreadcrumbsComponent,
    ],
    exports: [
        HeaderModule,
        NavigationModule,
        NavigationOptionsModule,
        FooterComponent,
        RibbonComponent,
        LayoutSwitcherComponent,
    ]
})
export class SmartadminLayoutModule {

}
