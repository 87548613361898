import {
    Component, Input, ViewChild, EventEmitter, Output, ChangeDetectionStrategy,
    ChangeDetectorRef,
    OnInit,
    OnDestroy
} from "@angular/core";
import { ModalDirective } from "ngx-bootstrap";
import { Promise } from "q";
import { ReportService } from "app/services/report.service";
import { Observable ,  Subscription ,  Subject } from "rxjs";
import { DataSource } from "@angular/cdk/collections";
import { SimpleDataSource } from "app/data/datasets/simple.datasource";
import { Config } from "app/config/config.service";
import { FormatHelper } from "app/shared/utils/format-helper";
import { TriCardPokerDetail } from "app/data/entities/tricardpoker-detail";
import { BlackjackIcon } from "app/data/entities/blackjack-icon";
import { PlayerGameService } from "app/generic-list/custom-actions/player-game-actions/player-game.service";
import { RowIterator } from "app/data/row-iterator";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

@Component({
    selector: "app-tricardpoker-details",
    templateUrl: "tricardpoker-details.component.html",
    styleUrls: ["tricardpoker-details.component.css"]
})
export class TriCardPokerDetailsComponent implements OnInit, OnDestroy {

    @Input() set rowData(value: RowIterator) {
        if (value) {
            this._rowData = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    @Input() set tokenhash(value: string) {
        if (value) {
            this._token = value;
        }
    }

    @Input() set datahash(value: string) {
        if (value) {
            this._encodedId = value;
            this.isGlobalGameDetail = false;
            this.getGameData();
        }
    }

    private _rowData: RowIterator;
    private _encodedId: string;
    private _token: string;
    currencyClassification: string;

    get row(): any {
        if (this._rowData) {
            return this._rowData.currentRow;
        }        
    }

    table1DisplayedColumns = ["action", "anteBet", "raiseBet", "pairPlusBet", "bonusBet"];

    table2DisplayedColumns = ["payAnte", "payRaise", "payPairPlus", "payBonus"];

    public tricardpokerDetails: Subject<TriCardPokerDetail[]>;

    public bShowMessageError: boolean = false;
    public errorCode: string="";
    public date: string;
    public time: string;
    public dealerCards: Array<string>;
    public dealerIcons: Array<BlackjackIcon>;
    public playerCards: Array<string>;
    public playerIcons: Array<BlackjackIcon>;    
    private subscriptions: Subscription = new Subscription();
    private currency: string;
    private playerGameServiceSubscription: Subscription;
    public isLoading: boolean;

    public isGlobalGameDetail: boolean = false;
    public globalGameDetailUrl: SafeResourceUrl;

    readonly IN_CASINO_GAMES_HISTORY_REPORT: number = 0;
    readonly GAMES_HISTORY_REPORT_GLOBAL_SERVICE: number = 1;

    constructor(
        private reportService: ReportService, 
        private config: Config,
        private playerGameService: PlayerGameService, 
        private cd: ChangeDetectorRef,
        private translate: TranslateService,
        private sanitizer: DomSanitizer
    ) {
        this.isGlobalGameDetail = false;
        const url = "";
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

        this.playerGameServiceSubscription = this.playerGameService.refresh$.subscribe(() => {
            if (this._rowData.currentRow.externalGameId === Number(this.config.gameDetailsReport.tricardpoker)){
                this.isGlobalGameDetail = false;
                this.getGameData();
            }
        });
        this.tricardpokerDetails = new Subject();
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.playerGameServiceSubscription.unsubscribe();
        this.subscriptions.unsubscribe();
    }

    get detailsDatasource(): DataSource<TriCardPokerDetail> {
        return new SimpleDataSource(this.tricardpokerDetails.asObservable());
    }

    getGameData() {
        const tricardpokerDetail = (this._rowData) ? 
            this.reportService.getTriCardPokerDetail(this.row.idStr) : 
            this.reportService.getTriCardPokerDetailEncoded(this._encodedId, this._token);        

        this.isLoading = true;

        tricardpokerDetail.subscribe(item => {

            if (this.translate.currentLang === undefined){
                this.translate.currentLang = item.languagecode ? item.languagecode : "en";
                this.translate.setDefaultLang(this.translate.currentLang);
            }       

            this.date = FormatHelper.shortDate(item.dateTime, this.translate.currentLang);
            this.time = FormatHelper.shortTime(item.dateTime, this.translate.currentLang);
            this.dealerIcons = item.dealerIcons;
            this.dealerCards = item.dealerHand.split(" ").filter(dc => dc.length > 0);
            this.playerIcons = item.playerIcons;
            this.playerCards = item.playerHand.split(" ").filter(dc => dc.length > 0);
            this.tricardpokerDetails.next([item]);
            this.currency = item.currency;
            this.currencyClassification = item.currencyClassification;
            this.getReportUrl(item);

            this.isLoading = false;
        },
        err => {
            this.translate.currentLang = "en";
            this.translate.setDefaultLang(this.translate.currentLang);
            this.bShowMessageError = true;
            this.errorCode =  err.status.toString();
            this.cd.detectChanges();
        });
        this.cd.markForCheck();
    }

    public getReportUrl(detail: TriCardPokerDetail): void{
        this.isGlobalGameDetail = (detail.gamesHistoryReportProviderId === this.GAMES_HISTORY_REPORT_GLOBAL_SERVICE ? true : 
            (detail.gamesHistoryReportProviderId === this.IN_CASINO_GAMES_HISTORY_REPORT ? false : false));
        const url = detail.globalGameDetailsUrl;
        this.globalGameDetailUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    public getImgUrl(url: string): string {
        return this.config.imageCdnUrl + url;
    }

    public getPayout(amount: number): string {
        return FormatHelper.currency(amount, this.currency, this.translate.currentLang, this.currencyClassification);
    }

    public getRaiseOrFold(value: any): string {
        return this.translate.instant(value);
    }    

    public getMessageError(error:string):string{
        this.bShowMessageError = true;
        if (error)
        {
            switch (error){
                case "400":
                    return "app.shared.labels.tokenExpired";
                    break;
                case "404":
                    return 'app.casinos.generic-list.error-no-data';
                    break;
                default:
                    return "app.auth.login.error-try-again-later";
            }
        }
      
    }


}
